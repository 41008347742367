const ko = {
  "global": {
    "language": "한국어",
    "customer_service": "고객센터",
    "customer_account_tips": "⚠️본인 채널이 아닐 경우, 서비스 이용에 제한이 있을 수 있습니다.",
    "customer_service_tips": "궁금한 부분이 있으시면, [{0}]로 문의해 주세요.🧏‍♀️",
    "customer_service_subtitle": "발생하신 문제에 대해 자세히 설명 부탁드리며, {0} 통해 연락주시기 바랍니다.",
    "customer_service_link": "고객센터",
    "customer_service_email": "고객센터 이메일",
    "email_holder": "이메일을 입력해 주세요.",
    "email_error": "이메일 형식이 맞지 않습니다.",
    "password_holder": "비밀번호를 입력해 주세요.",
    "password_error": "8~20자 대소문자, 특수문자, 숫자가 최소 1개 이상 포함되어 있어야 합니다.",
    "password_confirm_error": "비밀번호가 일치하지 않습니다.",
    "phone_holder": "휴대폰 번호를 입력해 주세요.",
    "code_holder": "인증번호를 입력해 주세요.",
    "code_error": "인증번호 형식이 맞지 않습니다.",
    "code_get": "인증번호 발송",
    "code_resend": "재발송",
    "agreement_all_agree": "모두 동의합니다.",
    "agreement_age_14": "만 14세 이상입니다.",
    "agreement_age_18": "만 18세 이상입니다.",
    "protocol_checkbox_user": "{0} 가입 이용 약관",
    "protocol_checkbox_privacy": "{0} 개인정보 정책",
    "agreement_message_tip": "약관에 대한 동의가 필요합니다.",
    "btn_add": "추가",
    "btn_change": "수정",
    "btn_delete": "삭제",
    "btn_confirm": "확인",
    "btn_consider": "생각해 보겠습니다.",
    "btn_close": "닫기",
    "btn_submit": "제출",
    "btn_cancel": "취소",
    "btn_know": "알겠습니다.",
    "btn_last_step": "이전",
    "btn_next_step": "다음",
    "btn_agree": "동의",
    "btn_disagree": "비동의",
    "btn_go": "바로가기",
    "btn_signin": "로그인",
    "btn_google_signin": "구글 로그인",
    "btn_signup": "회원가입",
    "btn_send": "발송",
    "btn_verify": "인증",
    "btn_done": "완료",
    "btn_save": "저장",
    "btn_put_away": "접기",
    "btn_expand": "더보기",
    "btn_edit": "수정",
    "btn_check": "더보기",
    "btn_view_in_mobile": "모바일로 보기",
    "modal_open_platform_title": "채널개설을 먼저 진행해 주세요.",
    "modal_open_platform_subtitle": "플랫폼 개통을 통해 더 많은 해외 구독자를 만나보세요.",
    "modal_open_platform_btn": "플랫폼 개통 신청",
    "total_count": "총 {0} 개 데이터",
    "list_no_more": "이미 모든 데이터를 확인했습니다.",
    "message_success_create": "신규 추가 성공",
    "message_error_create": "신규 추가 실패",
    "message_success_update": "수정 성공",
    "message_error_update": "수정 실패",
    "message_success_delete": "삭제 성공",
    "message_error_delete": "삭제 실패",
    "message_success_upload": "업로드 성공",
    "message_error_upload": "업로드 실패",
    "message_success_bind": "연동 성공",
    "message_error_bind": "연동 실패",
    "message_no_token": "로그인 시간이 초과되었습니다.다시 로그인 해 주세요.",
    "message_no_permission": "권한이 없습니다.",
    "message_sms_sended": "인증번호가 발송되었습니다.",
    "message_sms_error": "인증번호 발송이 실패했습니다.",
    "message_email_sended": "이메일로 인증번호를 발송했습니다.",
    "message_email_error": "이메일 발송이 실패했습니다.",
    "message_verify_success": "인증 성공",
    "message_verify_fail": "인증 실패",
    "message_code_invalid": "인증 코드가 잘못되었거나 만료되었습니다!",
    "not_found_title": "찾는 페이지가 없습니다.",
    "not_found_tips": "잘못된 경로로 들어왔습니다.",
    "empty_no_data": "데이터가 없습니다.",
    "empty_on_dev": "준비 중입니다.",
    "upgrading_tip": "아직 준비중이에요",
    "upload_avatar": "프로필 사진",
    "device_reminder_content": "본 사이트는 데스크탑 브라우저에 최적화되어 있습니다. 모바일 기기에서 접속 시 일부 기능이 제한될 수 있습니다.",
    "trial_watermark": "adobaro 체험판",
    "platform_hao_kan": "하오칸 비디오",
    "platform_tencent": "텐센트 비디오",
    "platform_iqiyi": "아이치이",
    "platform_bilibili": "비리비리",
    "platform_xi_gua": "시과 비디오",
    "platform_wei_bo": "웨이보",
    "platform_youtube": "유튜브",
    "platform_dou_yin": "도우인",
    "platform_red_book": "샤오홍슈",
    "platform_kuai_shou": "콰이쇼우",
    "platform_cloud_music": "왕이윈뮤직",
    "platform_wechat_video": "웨이신스핀하오",
    "platform_mei_pai": "메이파이",
    "platform_da_yu": "따위하오",
    "platform_dian_tao": "디엔타오",
    "platform_qq_world": "큐큐월드",
    "platform_tiktok": "틱톡",
    "platform_instagram": "인스타그램",
    "platform_twitter": "트위터",
    "country_USA": "미국",
    "country_CAN": "캐나다",
    "country_KRW": "한국",
    "country_CHN": "중국"
  },
  "dashboard": {
    "home_page": "대시보드",
    "material_management": "파일 드라이브",
    "content_management": "콘텐츠",
    "platform_management": "채널",
    "ai_subtitle": "AI 자막",
    "data_analysis": "데이터 분석",
    "commercial_monetization": "비즈니스 관리",
    "wallet": "내 지갑",
    "repost_management": "불펌 관리",
    "creative_laboratory": "실험실",
    "setting": "설정",
    "feedback": "피드백",
    "close_sidebar": "메뉴 접기",
    "upload_video": "업로드",
    "trial_btn_exit": "체험판 나가기",
    "customer_service": "고객센터",
    "member": "내 플랜",
    "member_expired_days": "종료 {0}일 전",
    "member_expire_today": "오늘 종료",
    "lang_language": "언어",
    "lang_zh_cn": "中文(简体)",
    "lang_ko_kr": "한국어",
    "lang_en_eu": "English",
    "auth": "Global PASS",
    "auth_certified": "인증완료",
    "auth_uncertified": "미인증",
    "account_management": "멀티채널 관리",
    "sign_out": "로그아웃",
    "out_of_service_title": "중국 노동절 연휴기간 서비스 이용 안내",
    "out_of_service_content": "안녕하세요, 팀 adobaro입니다.\n\n5월 1일 (수) ~ 5월 5일 (일)은 중국 노동절 연휴 기간입니다.\n\n연휴 기간 동안 각 영상 플랫폼의 일부 서비스 업무 시간이 제한될 수 있으며, \n이로 인해 adobaro 서비스 이용이 원활하지 않을 수 있습니다.\n\n항상 크리에이터 분들의 열정을 응원합니다!\n감사합니다.",
    "out_of_service_content_time": "5월 1일 (수) ~ 5월 5일 (일)",
    "out_of_service_guide": "adobaro 가이드북",
    "out_of_service_customer": "고객센터 문의하기",
    "out_of_service_tips": "고객센터 운영시간: [월~금] 9:30~18:30(KST), (공휴일 제외)"
  },
  "sign": {
    "signin_intro_title": "가장 안전하고 편리한 \n글로벌 진출 솔루션",
    "signin_intro_2_steps": "2단계 콘텐츠 검수과정 & 콘텐츠 소유권 보호로 더욱 안전한 해외 플랫폼 활동 지원",
    "signin_intro_cross_platform": "모든 플랫폼 업로드를 한번에!\n한번에 업로드 하고, 한눈에 확인하는 콘텐츠 데이터",
    "signin_login_title": "로그인",
    "signin_enter_password": "비밀번호 입력",
    "password_check_1": "8~20 글자",
    "password_check_2": "영문 대/소문자 포함",
    "password_check_3": "숫자, 특수문자 1개 이상 포함",
    "password_incorrect_format": "일치하지 않는 비밀번호입니다",
    "signin_title": "환영합니다. ^^",
    "signin_subtitle": "개통부터 수익 정산까지!\n중국 플랫폼 운영의 모든 노하우를 adobaro를 통해 만나 보세요.",
    "forgot_password": "비밀번호가 기억나지 않으신가요?",
    "signup": "가입",
    "signup_tip": "계정이 없다면 {0} 하기",
    "account_not_exist": "입력하신 계정 정보를 찾을 수 없습니다.다시 확인 후 시도해 주세요.",
    "signin_error": "로그인 실패",
    "google_signin_error": "구글 로그인 실패",
    "signin_warning": "해외IPㆍ보안 이슈가 감지되었습니다.가입하신 이메일에서 보안 인증을 진행해 주세요.",
    "signin_fail": "비밀번호가 맞지 않습니다.",
    "too_many_requests": "잠시 후 다시 시도해 주세요.",
    "modal_signup_tips1": "비밀번호 설정이 가능한 링크를 이메일로 보내드렸어요.",
    "modal_signup_tips2": "메일함을 열어 비밀번호 설정을 완료해주세요",
    "modal_signup_tips3": "*이메일을 받지 못 하셨다면 스팸함을 확인해주세요.",
    "modal_signup_btn": "네, 이메일을 확인할게요",
    "modal_welcome_tips1": "아도바로에 오신걸 환영합니다!",
    "modal_welcome_tips2": "크리에이터 성장을 위한 All-in-One 스마트 솔루션,",
    "modal_welcome_tips3": "지금 바로 경험하세요",
    "modal_welcome_btn": "채널 개설 시작하기",
    "modal_forgot_title": "비밀번호 찾기",
    "modal_forgot_tips1": "비밀번호 재설정 링크가 첨부된 이메일을 보내드릴게요",
    "modal_forgot_tips2": "아래 버튼을 클릭하고, 메일함을 확인해주세요",
    "modal_forgot_tips3": "*메일을 받지 못하셨나요? 스팸 메일함도 확인해주세요.",
    "modal_forgot_btn": "비밀번호 재설정 시작하기",
    "forgot_email_send": "인증 메일이 발송되었습니다.",
    "forgot_email_send_error": "이메일 발송 실패",
    "verify_success": "인증 성공",
    "verify_fail": "인증 실패",
    "verify_success_tip": "인증이 완료되었습니다. 감사합니다.",
    "verify_fail_tip": "잘못된 링크 혹은 인증 시간이 초과되었습니다.",
    "btn_signin_retry": "다시 로그인하기",
    "signup_intro_title": "You Create, \nWe Navigate. ",
    "signup_intro_revenue": "크리에이터 수익 극대화",
    "signup_intro_revenue_subtitle": "월간 50억 이상 방문자가 활동하는 해외 플랫폼에서\n크리에이터 활동 수익을 늘려보세요!",
    "signup_intro_operation": "안전하고 스마트한 콘텐츠 운영",
    "signup_intro_operation_subtitle": "AI로 더욱 똑똑하고 빠르게 실행하는\n해외 플랫폼 정책 맞춤형 콘텐츠 자동 최적화 ",
    "signup_create_account": "회원가입",
    "signup_agree_tips": "아도바로 회원가입 시 {0} 및 {1}에 동의합니다",
    "signup_agree_tips_terms": "이용약관",
    "signup_agree_tips_privacy": "개인정보 처리방침",
    "account_exist": "이미 가입된 계정입니다.",
    "signup_age_14": "만 14세 이상입니다.",
    "signup_user_protocol": "adobaro 사용자 사용계약",
    "signup_privacy_protocol": "adobaro 개인정보 정책",
    "signup_marketing_protocol": "(선택) 마케팅 정보 수신 동의서",
    "signin": "로그인",
    "signin_tip": "계정이 있다면, {0} 해 주세요",
    "signup_email_send": "인증 이메일이 발송되었습니다. \n이메일 인증 후, 비밀번호 설정이 진행됩니다.",
    "signup_email_send_error": "가입 실패",
    "password_intro_title": "Enter Chinese \nPlatforms",
    "password_set": "신규 비밀번호 설정",
    "password_reset": "비밀번호 재설정",
    "password_set_subtitle": "이메일 인증을 통해 본인 인증이 완료되었습니다.\n안전한 비밀번호를 설정해 주세요.",
    "password_reset_subtitle": "이메일 인증을 통해 본인 인증이 완료되었습니다.\n안전한 비밀번호로 재설정해 주세요.",
    "btn_password_set": "비밀번호 설정",
    "btn_password_reset": "비밀번호 재설정",
    "password_set_success": "비밀번호가 정상적으로 설정되었습니다.",
    "password_reset_success": "비밀번호가 정상적으로 재설정 되었습니다.",
    "password_set_fail": "비밀번호 설정이 실패했습니다.",
    "password_reset_fail": "비밀번호 재설정 실패",
    "password_set_code_invalid": "인증 유효시간이 초과되었습니다.가입 절차를 다시 진행해 주세요.",
    "password_reset_code_invalid": "인증 유효시간이 초과되었습니다. 변경을 재신청해 주세요."
  },
  "userAgreement": {
    "title": "Adobaro 사용자 사용계약",
    "updated": "갱신시간：2023년【10】월【31】일",
    "effective": "효력발생 : 2023년【10】월【31】일",
    "introduction": "【머릿말】",
    "introduction_1": "《adobaro사용자사용계약》(이하'본계약')은 귀하와 adobaro 간 adobaro 의 각종 서비스사용에 관한 법적 계약서입니다. 'adobaro'란, adobaro 운영사인 adoba cultural media (Sichuan) Co., LTD. (安哆巴文化传媒（四川）有限公司) 및 관계사(이하 '회사')를 말합니다.",
    "introduction_2": "본 계약서에서 귀하의 권익(가능성)과 중대한 관계성이 있는 조항(adobaro 면책조항, 귀하의 권리 제한 조항, 분쟁의 해결 및 사법관할권 조항 등 포함)은 adobaro 에서 폰트를 강조하는 방식으로 특별히 강조하고 있사오니 주의해서 확인하시기 바랍니다. adobaro 서비스 사용 전 반드시 숙독하고 충분히 이해한 뒤에 본 계약의 전체 조항을 수용해야 합니다.",
    "introduction_3": "<adobaro 개인정보 정책>, <도움말 센터> 및 adobaro 에서 발표하는 기타 계약 규범, 성명, 공지, 정책 등(이하 '기타 adobaro 규칙'으로 통칭)은 본 계약과 분리될 수 없는 구성 요소로서 귀하가 adobaro 서비스 이용 시에도 반드시 준수해야 합니다.",
    "introduction_3_bold": "본 계약을 수용하고 동의함은 기타 adobaro 규칙도 함께 수용하고 동의하는 것으로 간주합니다. 또한 기타 adobaro 규칙을 수용하거나 동의함 역시 귀하가 본 계약을 수용하고 동의하는 것으로 간주합니다.",
    "introduction_4": "귀하는 온전한 민사적 행위능력 및 권리능력을 갖추고 본 계약에 동의하고 준수할 수 있으며, 본 계약에 규정된 모든 사항에 대해 독립적으로 법적 책임을 부담함을 확인하시기 바랍니다. 만일 귀하가 상기 행위에 적절한 민사적 행위능력을 갖추지 못한 경우, 반드시 법적 보호자의 동의를 얻어야 합니다. 귀하가 미성년인 경우 법적 보호자를 동반하여 본 계약 동의 여부를 읽고 판단해야 합니다. 미성년자 조항에 유의하시기 바랍니다. 미성년자가 본 계약에 정해진 권리 및 의무를 이행할 경우, 이는 법적 보호자의 승인을 이미 얻은 것으로 간주합니다.",
    "content_1": "서비스 설명",
    "content_1_1": "adobaro 는 다음을 포함하되 이에 국한하지 않는 서비스를 제공합니다:",
    "content_1_1_1": "adobaro 웹사이트: www.adobaro.com(및 기타 adobaro 가 운영하는 모든 웹사이트 또는 위탁 계약 사이트 또는 소프트웨어 관련 웹사이트 또는 소프트웨어);",
    "content_1_1_2": "adobaro 가 직접 운영하거나 제3자와 협력 운영하는 PC, 태블릿, 모바일, TV, 셋톱박스, 휴대장치, 차량용 태블릿 장치, 스마트 오디오 장치 등을 포함하되 이에 국한하지 않는 모든 고객간 단말 제품;",
    "content_1_1_3": "adobaro 사용자 소재 관리, 콘텐츠 관리, 플랫폼 관리;",
    "content_1_1_4": "adobaro 가 직접 소유하거나 운영하는 서버, 웹사이트 저장공간;",
    "content_1_1_5": "adobaro 가 귀하에게 제공하는 각종 가치 증진 서비스;",
    "content_1_1_6": "adobaro 가 제공하는 기타 기술 및/또는 서비스, 구체적으로 adobaro 가 실제 제공하는 내용을 기준으로 하며, adobaro 는 자체적으로 추가, 중단 또는 기타 조정을 진행할 수 있습니다.",
    "content_1_2": "adobaro 에서 제공하는 서비스는 상기 1.1.1 및 1.1.2 에 기술한 플랫폼(이하 'adobaro 관련 플랫폼') 내에서만 사용하도록 제한하며, 악의적 해킹이나 불법적 수단으로 adobaro 에서 제공하는 서비스 및 adobaro 관련 플랫폼을 분리하는 모든 행위는 본 계약에 약정된 adobaro 제공 서비스에 포함되지 않습니다. 이로 인해 발생하는 모든 결과는 행위자의 책임이며 adobaro 는 적법하게 행위자의 책임을 추궁할 법적 권리를 갖습니다. adobaro 가 제공하는 서비스는 사용자의 비상업적 사용만을 목적으로 하며, 사용자는 adobaro 의 사전 서명 동의 없이 상업적 목적으로 adobaro 에서 제공하는 서비스를 사용할 수 없습니다.",
    "content_1_3_bold": "adobaro 공식 배포는 고객 단말 등록, 로그인, 다운로드(iOs, Android 등 스마트 플랫폼을 포함하되 이에 국한하지 않음), adobaro 서비스 이용의 유일한 합법적 방식으로, 사용자가 그 어떠한 기타 경로, 채널, 방법으로 취득한 adobaro 서비스(계정, 고객 단말 다운로드 등을 포함하되 이에 국한하지 않음)는 일괄 불법 취득으로 간주됩니다. adobaro 는 해당 서비스의 효력을 인정하지 않으며, 발견 즉시 삭제, 초기화, 차단 등 조치를 취할 것이므로 이로 인해 발생하는 모든 불이익은 고객이 스스로 부담해야 합니다.",
    "content_1_3": "사용자는 자신의 계정과 비밀번호를 적절하게 보관하고 비밀번호 보안을 강화하며 계정 유출이나 탈취를 방지해야 합니다. 사용자 계정 유출 또는 탈취로 인해 발생하는 모든 손실에 대해 adobaro 는 배상의 책임을 부담하지 않습니다.",
    "content_1_4": "adobaro 는 전항에 대해 사용자에게 (팝업 공지, 웹사이트 홈페이지 공지, 시스템 메시지 등을 포함하되 이에 국한하지 않음)을 통해 adobaro 서비스 관련 소프트웨어 수정, 변경, 업그레이드를 고지합니다.",
    "content_1_4_bold": "만일 사용자가 adobaro 관련 소프트웨어 수정, 변경, 업그레이드에 동의하지 않을 경우에는 사용을 직접 거절, 중지, 취소할 수 있습니다. 그렇지 아니한 사용자는 adobaro 관련 소프트웨어 수정, 변경, 업그레이드에 동의하였으므로 여전히 본 계약 조항의 제약을 받는 것으로 간주합니다.",
    "content_1_5": "본 계약에서 지칭하는 '사용자' 는 adobaro 계정을 취득한 사용자(이하 '등록 사용자') 및 adobaro 계정을 아직 취득하지 않은 사용자(이하 '미등록 사용자')를 포함합니다. adobaro 에 등록되지 않은 제품/또는 서비스의 사용자는 adobaro 제품을 다운로드 및/또는 adobaro 서비스 이용 시 자동으로 adobaro 의 '미등록 사용자'가 됩니다.",
    "content_1_6": "주의: adobaro 제품 및 서비스가 다양하므로 제공되는 제품과 서비스 콘텐츠가 상이할 수 있으며, 본 계약은 adobaro 통합 표준 사용자 서비스 계약에 해당합니다. adobaro 는 adobaro 의 특정 제품/서비스에 대해 사용자 서비스 계약을 별도 제정하여 구체적으로 귀하와 adobaro 간의 서비스 콘텐츠, 서비스 규칙 등 내용을 약정하므로, 당사는 귀하가 특별 고객 서비스 계약의 전체 내용을 반드시 숙지한 뒤 해당 특정 제품/서비스를 이용할 것을 권장합니다.",
    "content_2": "계정 등록",
    "content_2_1": "adobaro 가 제공하는 네트워크 저장 공간 내 방송, 텍스트, 미술 촬영 콘텐츠 업로드 및 배포 등 일부 adobaro 서비스는 등록 고객에게만 제공되므로, 귀하는 본 계약과 기타 adobaro 규칙에 규정된 프로세스에 따라 고객 등록을 진행하고 등록 정보의 진실성, 정확성, 완전성을 확인해야 하며, 등록 정보 변경 시 즉시 변경해야 합니다.",
    "content_2_2": "귀하는 adobaro 에 등록한 계정(이하 '계정')으로 진행한 모든 행위에 대해 책임을 부담하고 중화인민공화국(이하 '중국' 또는 '국가') 법률, 사회 도덕 및 미풍양속과 정보 진실성 등 원칙을 준수해야 합니다. 허위 정보를 배포하거나 타인의 성명, 명칭, 초상, 호칭을 도용하거나 기타 충분히 혼동을 일으킬 수 있는 방식으로 계정을 설정할 수 없으며, adobaro 를 포함한 모든 주체의 합법적 권익을 침해할 수 없습니다.",
    "content_2_3": "귀하는 계정 및 계정을 통해 adobaro 가 제공하는 모의 제품과 서비스에 대한 사용권만을 가지며, 계정 및 해당 모의 제품과 서비스의 소유권은 adobaro 가 가집니다(법적으로 별도 규정된 경우 제외). adobaro 의 서면 동의 없이는 그 어떠한 방식으로도 계정 사용권을 처분(증여, 임대, 양도, 판매, 저당, 계승, 타인 사용 허가를 포함하되 이에 국한하지 않음)할 수 없습니다.",
    "content_2_3_bold": "adobaro 가 사용자가 계정의 최초 등록자가 아님을 발견하였거나 합리적으로 판단할 수 있는 경우, adobaro 는 귀하에게 통보 없이 해당 등록 계정에 제공되는 서비스를 중단하거나 종료할 수 있으며, 해당 계정을 삭제할 수 있습니다. 이로 인해 발생하는 콘텐츠 또는 모의 자산의 손실 등은 귀하 본인 또는 계정 사용자가 부담해야 합니다.",
    "content_2_4": "사용자는 계정 정보, 계정 비밀번호 및 기타 계정 관련 정보, 자료를 적절히 보관해야 합니다. 귀하의 사유로 계정 정보, 자료, 데이터 변경, 멸실 또는 재산 손실 등이 발생한 경우, 반드시 즉시 adobaro 에 이를 통보하고 법적 책임을 부담해야 합니다.",
    "content_2_5": "제3자 웹사이트 또는 서비스를 통해 adobaro 계정을 등록한 경우, adobaro 의 별도 설명이 없는 한 adobaro 가 이들 웹사이트 또는 서비스를 운영하지 않으므로, 제3자 웹사이트의 안전성과 사용성을 사용자 스스로 판단하여 관련 위험과 책임을 부담해야 합니다.",
    "content_2_6": "귀하는 다음에 동의하고 서약합니다:",
    "content_2_6_1": "귀하의 계정 또는 비밀번호가 허가 없이 사용되거나, 보안 문제가 발생한 경우 반드시 유효한 방법으로 adobaro 에 이를 통보합니다;",
    "content_2_6_2": "adobaro 에 로그인하거나 관련 서비스를 사용한 뒤에는 관련 계정 등을 안전하게 로그아웃합니다;",
    "content_2_6_3": "adobaro 가 전자우편, 고객 단말, 웹페이지 또는 기타 합법적 방식으로 귀하에게 전송하는 정보 및 기타 관련 정보를 수신하는데 동의합니다.",
    "content_2_7": "귀하는 다음의 행위에 종사하지 않을 것을 동의하고 서약합니다:",
    "content_2_7_1": "타인의 정보를 도용하여 adobaro 계정 로그인;",
    "content_2_7_2": "타인의 합법적 허가 없이 타인의 명의로 adobaro 계정 등록;",
    "content_2_7_3": "동일한 신원 정보로 다수의 adobaro 계정 등록(adobaro 심사를 거쳐 인정된 다수 adobaro 계정의 실제 지배자가 동일인인 경우 포함);",
    "content_2_7_4": "타인의 adobaro 계정, 회원 아이디 등을 절도, 도용;",
    "content_2_7_5": "모욕성, 비방성, 음란성, 정치성 등 중화인민공화국 법률, 도덕, 미풍양속을 저해하는 어휘로 adobaro 계정 등록;",
    "content_2_7_6": "불법적으로 adobaro 관련 서비스 리소스를 점유하기 위해 정당하거나 정당하지 아니한 수단을 통하여 악의적으로 웹사이트 허점을 이용;",
    "content_2_7_7": "타인의 합법적 권일 및 기타 콘텐츠를 침해하는 행위.",
    "content_2_8": "귀하는 상기 조항을 위반한 사용자에게 adobaro 가 등록 금지 및/또는 계정 차단 등 조치를 취할 수 있음을 알고 이에 동의합니다.",
    "content_2_9": "adobaro 에서 제공하는 서비스는 adobaro 가 중국의 제3자 네트워크 운영 플랫폼(이하: 중국 플랫폼 또는 플랫폼)을 포함하므로, 귀하는 다음 내용에 유의할 것을 권장합니다:",
    "content_2_9_1": "등록 계정과 운영 플랫폼 계정의 일관성을 보장하기 위해, 귀하는 반드시 본인의 휴대전화번호를 플랫폼 개통 계정으로 해야 합니다;",
    "content_2_9_2": "adobaro 는 adobaro 에 게시된 플랫폼에 대해서만 개통 신청을 지원합니다. adobaro 에 게시되지 않은 플랫폼은 adobaro 에서 현재 지원하지 않거나 운영 관련 책임을 부담하지 않음을 의미합니다;",
    "content_2_9_3": "adobaro 에 개통된 플랫폼은 귀하 본인이 직접 신청 양식에 따라 직접 신청을 진행합니다. adobaro 는 모든 신청을 귀하 본인이 진행한 것으로 간주합니다. 귀하가 신청하면 adobaro 가 귀하의 휴대전화번호로 플랫폼 신청 등록을 진행하는 데 동의한 것으로 간주합니다;",
    "content_2_9_4": "중국 플랫폼은 비정기적, 무작위적으로 해당 운영 규칙을 변경할 수 있으므로 adobaro 는 변경 정보를 수신한 즉시 변경된 규칙을 공지 형태로 귀하에게 고지합니다. 따라서 귀하는 adobaro 공지에 유의하여 플랫폼 운영 정책 변경으로 인한 손실을 방지해야 합니다;",
    "content_2_9_5": "중국 플랫폼 개통 전, 귀하의 휴대전화번호를 장기적으로 사용할 수 있는지 확인해야 합니다. 만약 귀하의 휴대전화번호가 adobaro 에서 플랫폼을 성공적으로 개통하였으나 휴대전화번호를 사용하지 않거나 복구할 수 없어 플랫폼에 로그인할 수 없는 경우, 또는 장기 미사용 등 사유로 해당 플랫폼의 계정이 폐기된 경우 발생하는 손실은 귀하 본인이 부담합니다(플랫폼 계정 손실, 모의 자산 손실 포함); 귀하가 신규 휴대전화번호로 플랫폼 개통을 신청할 경우, 해당 휴대전화번호에 대응하는 계정은 새로운 신규 계정이 되어 이전 휴대전화번호와 상충 또는 교차되지 않습니다.",
    "content_2_10": "귀하는 계정을 사용해 adobaro 에서 제공하는 adobaro 시스템 산하 모든 제품에 등록함을 인지하고 이해하였습니다.",
    "content_2_11": "귀하는 adobaro 가 법적으로 허가하는 범위 내에서 상황에 따라 계정 사용권을 회수할 수 있으며, 이 때 사용자에게 별도로 통보하여 동의를 구하지 아",
    "content_2_12": "귀하가 본 웹사이트의 계정 및 해당 계정으로 배포하는 모든 콘텐츠에 대해, adobaro 는 귀하 계정과 자동 동기화하여 회사 및/또는 관계사 및/또는 회사 운영을 제어하는 기타 소프트웨어 및 웹사이트, 회사 및/또는 관계사 및/또는 지주사가 적절한 형태로 기타 소프트웨어 및 웹사이트에 게재할 수 있음을 수용하고 이에 동의합니다. adobaro 에서 귀하가 콘텐츠를 배포, 수정, 삭제하는 경우, 상기 일련의 기타 소프트웨어 및 웹사이트도 함께 동기화합니다. 이미 등록한 계정이나 제3자 소프트웨어용 계정으로 회사 및/또는 관계사 및/또는 지주사가 운영하는 기타 소프트웨어 제품 및 웹사이트(있을 경우)에 등록할 경우, 반드시 해당 소프트웨어 및 웹사이트 자체의 <사용자 계약> 및 기타 계약조항 규정을 준수해야 합니다. 논쟁을 피하기 위해, 귀하는 본 웹사이트의 계정 및 계정에서 배포한 콘텐츠의 전체 또는 일부가 회사에서 제공하는 콘텐츠 서비스, 플랫폼 신청 및 개통을 진행했던 고객 단말 애플리케이션 및 웹사이트에 동기화됨을 확인하고 이에 동의합니다. 해당 라이선스는 해당 라이선스 기간 내 무료이며, 철회 불가하고, 독점적이며, 지역적 제약을 받지 않습니다.",
    "content_2_13": "계정 리소스를 충분히 사용하기 위해 adobaro 는 각 플랫폼별 규칙에 따라 운영함을 이해하고 이에 동의합니다.",
    "content_3": "adobaro 콘텐츠",
    "content_3_1": "adobaro 콘텐츠란, adobaro 가 제공하는 서비스입니다. 업로드, 게시 또는 기타 수단을 통해 전송 또는 전파하는 모든 정보, 자료, 텍스트, 소프트웨어, 음악, 오디오, 사진, 이미지, 영상, 메시지, 링크 또는 기타 자료이며 공개 여부를 구분하지 않습니다(이하 '콘텐츠'). 콘텐츠 제공자, 업로더는 자신이 제공, 업로드하는 콘텐츠에 대한 모든 책임을 부담하고",
    "content_3_1_bold": "adobaro 에게 손실을 끼치는 경우 adobaro 에 대한 배상의 책임도 함께 부담합니다. 제3자가 사용자가 업로드한 콘텐츠에 대하여 adobaro 에게 권리를 주장할 경우, adobaro 는 콘텐츠 제공자, 업로더에게 사전 통보 없이 바로 삭제, 차단, 숨김 처리 등 필요 조치를 취할 수 있습니다.",
    "content_3_2": "adobaro 에서 업로드 또는 배포하는 콘텐츠에 대해 귀하는 합법적 저작권 또는 상응하는 라이선스를 보유해야 하며, adobaro 는 전술한 콘텐츠를 표시, 배포, 홍보할 수 있습니다.",
    "content_3_3": "adobaro 는 추가적으로 귀하에게 글로벌 표시 및 홍보 서비스를 제공하며, 귀하는 adobaro 가 귀하의 계정으로 배포되는 모든 콘텐츠를 adobaro 가 직접 운영하거나 제3자와 협력하여 운영하는 제품에 게시할 수 있음에 동의합니다. 이는 PC, 태블릿, 모바일, TV, 셋톱박스, 휴대장치, 차량용 태블릿 장치,스마트 오디오 장치 등 모든 고객 단말 소프트웨어 및/또는 웹사이트를 포함하되 이에 국한하지 않습니다. 그 밖에, 귀하는 adobaro 가 콘텐츠를 게시할 때 직접 또는 제3자에 위탁하여 필요한 처리(번역, 편집, 수정 등을 포함하되 이에 국한하지 않음)를 진행함에 동의합니다. 귀하는 adobaro 가 자체적으로 또는 제3자에 위탁하여 상기 콘텐츠, adobaro 제품 및 관련 서비스, adobaro 및/또는 adobaro 브랜드에 관한 모든 홍보, 마케팅 및/또는 R&D 를 진행함에 적절한 방식으로 상기 콘텐츠(전부 또는 일부)를 개발하고 사용할 수 있음을 확인하고 이에 동의합니다. 분쟁을 피하기 위해, 귀하는 상기 콘텐츠에 귀하가 소유하거나 사용을 허가 받아 콘텐츠에 삽입한 개인의 초상, 이미지, 상표, 서비스 로고, 브랜드, 명칭, 마크 등이 포함된다는 것을 인지하고 이에 동의합니다.",
    "content_3_4": "adobaro 가 사전에 예견할 수 없고 합리적으로 제어할 수 없는 adobaro 서비스를 통해 업로드 되는 콘텐츠로 인해 귀하가 adobaro 서비스 이용 시 불쾌하거나 불편하거나 혐오감을 느끼게 하는 일부 콘텐츠를 접할 수 있음을 인지하였으므로, 귀하는 이로 인해 adobaro 에 제기하는 모든 청구권을 포기하는데 동의합니다. 단, adobaro는 전술한 콘텐츠의 전송을 중지하고 이에 상응하는 조치를 취할 수 있으며, 이는 귀하가 지속적으로 adobaro 의 일부 또는 전체 서비스를 사용할 수 없도록 서비스 제공을 중단하여 관련 기록을 관계 기관에 보고하는 것을 포함하되 이에 국한하지 않습니다.",
    "content_4": "사용 규칙",
    "content_4_1": "사용자는 adobaro 서비스 이용 시 반드시 다음 법규를 준수합니다:",
    "content_4_1_1": "<중화인민공화국국가기밀보장법(中华人民共和国保守国家秘密法)>",
    "content_4_1_2": "<중화인민공화국저작권법(中华人民共和国著作权法)>；",
    "content_4_1_3": "<중화인민공화국컴퓨터시스템안전보호조례(中华人民共和国计算机信息系统安全保护条例)>；",
    "content_4_1_4": "<컴퓨터소프트웨어보호조례(计算机软件保护条例)>；",
    "content_4_1_5": "<네트워크정보콘텐츠생태계관리규정(网络信息内容生态治理规定)>",
    "content_4_1_6": "<정보네트워크전파권보호조례(信息网络传播权保护条例)>",
    "content_4_1_7": "<중화인민공화국네트워크안전법(中华人民共和国网络安全法)>；",
    "content_4_1_8": "기타 법률, 법규, 정관, 조례 등 법적 구속력을 가진 규범.",
    "content_4_2": "사용자는 다음 행위에 종사할 수 없습니다:",
    "content_4_2_1": "헌법에 명시된 기본 원칙에 반하거나 국가 안보를 위협하거나 국가 기밀을 누설하거나 국가 정권을 전복하거나 국가 통일을 파괴하거나 민족의 단결을 저해하거나 국가 명예와 이익을 손상하거나 민족 혐오, 차별을 조장하거나 민족의 단결을 저해하거나 국가 종교 정책을 파괴하거나 사이비 종교나 미신을 조장하거나 음란물, 도박, 폭력, 강력범죄, 테러 또는 범죄 교사에 관련되거나, 타인을 모욕 또는 비방하거나 타인의 합법적인 권익을 침해하는 등 법적, 행정적으로 금지된 콘텐츠 또는 타인에게 반감을 사는 정보, 자료, 텍스트, 소프트웨어, 음악, 사진, 이미지, 메시지 또는 기타 자료를 제작, 업로드, 복제, 전송, 전파하는 행위;",
    "content_4_2_2": "미성년자에게 악영향을 미치는 모든 행위;",
    "content_4_2_3": "개인이나 기관을 사칭하거나 허위나 거짓된 방식으로 개인이나 기관을 오도하는 행위;",
    "content_4_2_4": "제목을 위조하거나 다른 방법으로 자료를 식별하여 해당 콘텐츠가 adobaro 에서 전송한 것으로 오해하도록 하는 행위;",
    "content_4_2_5": "전송 권한이 없는 콘텐츠(예: 내부 자료, 기밀 자료)를 업로드, 게시, 전자우편 발송 또는 기타 방식으로 전송하는 행위;",
    "content_4_2_6": "타인의 특허, 상표, 저작권, 영업 기밀 등 지적재산권을 침해하는 콘텐츠를 업로드, 게시, 전자우편 발송 또는 기타 방식으로 전송하는 행위;",
    "content_4_2_7": "광고물, 판촉물, '스팸 메일' 등을 업로드, 게시, 전자우편 발송 또는 기타 방식으로 전송하는 행위;",
    "content_4_2_8": "타인을 스토킹하거나 다른 방법으로 괴롭히는 행위;",
    "content_4_2_9": "컴퓨터 소프트웨어, 하드웨어 또는 통신장비 성능을 방해, 파괴하거나 제한하는 바이러스 또는 기타 컴퓨터 코드, 파일, 프로그램 자료를 업로드, 게시, 전자우편 발송 또는 기타 방식으로 전송하는 행위;",
    "content_4_2_10": "adobaro 서비스 또는 adobaro 서비스와 연결된 서버와 네트워크를 방해하거나 파괴하는 행위 또는 본 계약을 준수하지 않는 행위;",
    "content_4_2_11": "adobaro 사전 서명 승인 없이 모든 방법(로봇 프로그램, 스파이더 프로그램, 크롤러 소프트웨어 등 모든 자동 프로그램, 스크립트, 소프트웨어를 포함하되 이에 국한하지 않음)과 사유로 자발적 또는 타인에게 협조하거나 위탁받아 플랫폼 서비스, 콘텐츠, 데이터를 취득하는 행위;",
    "content_4_2_12": "귀하는 업로드 콘텐츠가 광전총국(广电总局) 관련 규정을 위반하지 아니함을 보장해야 하며, 이는 <인터넷 영상 프로그램 서비스 관리 규정(互联网视听节目服务管理规定)>(광전총국 56호 령(广电总局56号令))등을 포함하되 이에 국한하지 않습니다. 업로드한 방송은 반드시 법률 및 행정 법규, 관계 부처 정관 규정에 부합해야 하며 다음의 내용을 담지 않아야 합니다:",
    "content_4_2_12_1": "헌법에 명시된 기본 원칙에 반하는 내용;",
    "content_4_2_12_2": "국가 통일, 주권, 영토 완전성을 해치는 내용;",
    "content_4_2_12_3": "국가 기밀을 누설하거나 국가 안보를 저해하거나 국가의 명예와 이익을 손상하는 내용;",
    "content_4_2_12_4": "민족 혐오, 민족 차별을 조장하고 민족의 단결을 저해하거나, 민족적 미풍양속과 습관을 손상하는 내용;",
    "content_4_2_12_5": "사이비 종료나 미신을 조장하는 내용;",
    "content_4_2_12_6": "사회 질서를 어지럽히고 사회 안정을 파괴하는 내용;",
    "content_4_2_12_7": "미성년자를 불법적 범죄, 음란물, 도박, 테러리즘으로 유인하는 내용;",
    "content_4_2_12_8": "타인을 모욕하고 비방하거나 국민의 사생활을 침해하는 등 타인의 합법적 권익을 참해하는 내용;",
    "content_4_2_12_9": "사회 공공질서를 해치고 민족의 우수한 문화와 전통을 손상하는 내용;",
    "content_4_2_12_10": "법률, 행정 법규 및 국가 규정에서 금지하는 기타 내용.",
    "content_4_2_13_bold": "딥러닝이나 증강현실 등 신기술을 사용해 허위 정보 등 법률에서 금지하는 정보를 제작, 배포, 전파할 수 없습니다. 딥러닝, 증강현실 등 신기술을 적용해 제작한 비현실적 영상정보를 배포, 전파할 경우, 반드시 눈에 띄는 방법으로 표시해야 하며 그렇지 않은 경우 adobaro 는 관련 콘텐츠와 계정에 대하여 로고를 추가하거나 제한하거나 차단 등 조치를 취할 수 있습니다.",
    "content_4_2_13": "귀하가 제작, 배포, 전파하는 콘텐츠는 반드시 <네트워크 영상 정보 서비스 관리 규정(网络音视频信息服务管理规定)> 등 법률 규정 및 adobaro 에서 발표한 창작 규정에 부합해야 하며,",
    "content_4_2_14": "사용자가 제공한 업로드 콘텐츠에 상기 정책 및 법률을 위반하는 정보 또는 콘텐츠가 포함되어 발생한 모든 불이익은 사용자가 직접 부담해야 하며, 이로 인해 adobaro 에 불리한 영향이 미친 경우 귀하는 책임을 지고 불이익을 해소해야 합니다. 또한 귀하는 이로 인해 발생한 모든 손실에 대한 배상 의무를 집니다.",
    "content_4_3": "사용자는 그 어떤 채널 또는 미디어(자체 미디어 등을 포함하되 이에 국한하지 않음)를 통해서도 'adobaro 와 협력', 'adobaro 공동 출품' 등 'adobaro' 브랜드 텍스트가 있는 영상을 배포할 수 없습니다. 만일 사용자가 협력 방송 홍보를 위해 영상 자체를 언급하더라도 adobaro 와의 관계를 언급하거나 임의로 adobaro 브랜드를 가지고 홍보할 수 없습니다. 'adobaro' 가 포함되는 모든 원고는 반드시 adobaro 해당 부서의 서면 동의가 필요하며, 이를 준수하지 아니하여 adobaro 에게 발생하는 모든 손실은 귀하가 배상의 책임을 집니다.",
    "content_4_4": "유료 plan 사용 규칙",
    "content_4_4_1": "사용자가 adobaro.com에서 유료 plan을 주문하는 것은 adobaro사용자 사용계약을 읽고 그 내용을 이해했으며, 사용자가 직접 선택한 유료 plan의 빌링에 맞춰 자동으로 매월 혹은 매년 비용이 청구될 수 있다는 것에 동의한 것을 의미합니다.",
    "content_4_4_2": "사용자는 유료 plan을 1개월 동안 무료로 사용할 수 있습니다. 무료 사용 기간이 끝나기 전에 직접 취소하지 않으면, 1개월 이후 자동으로 결제되며 매월 혹은 매년 갱신됩니다. 무료 기간은 adobaro내부 정책에 의해 변경되거나 삭제될 수 있습니다.",
    "content_4_4_3": "유료 plan이 갱신되는 기간 동안 사용자가 서비스를 이용하지 않더라도 이용 기간에 대해 연장하거나, 환불처리되지 않습니다.",
    "content_4_4_4": "유료 plan은 매월 혹은 매년 청구되며, 이는 사용자가 구독신청을 취소하지 않는 한 계속해서 비용이 청구된다는 것을 의미합니다. 구독신청의 취소에 대한 책임은 사용자에게 있습니다. 이메일을 통한 요청이나, 고객센터를 통해 구독신청을 취소하더라도 그것은 취소로 인정되지 않습니다. 사용자는 언제든지 plan 섹션에서 사용자의 구독신청을 취소할 수 있습니다.",
    "content_4_4_5": "사용자가 이미 결제한 기간이 끝나기 전에 유료 plan을 해지하더라도 유료 plan의 남은 기간은 서비스가 유지됩니다.",
    "content_4_4_6": "매월 혹은 매년 결제한 비용은 환불이 되지 않습니다. 서비스 자동 갱신으로 비용이 청구되고 있다면 구독신청을 취소해야 다음 결제일로부터 비용이 청구되지 않습니다.",
    "content_4_4_7": "각 플랜의 혜택 및 가격",
    "content_4_4_8": "각 플랜의 혜택 및 할인률에 따른 가격은 내부 사정에 의해 변경될 수 있습니다.",
    "plan_project": "항목",
    "plan_free": "Free",
    "plan_basic": "Basic",
    "plan_pro": "Pro",
    "plan_original": "정가",
    "plan_price": "가격",
    "plan_discounts": "할인률",
    "plan_discounts_prices": "할인된 가격",
    "plan_special_offer": "프로모션",
    "plan_month": "월 구독",
    "plan_year": "년 구독",
    "plan_special_des": "가입 시 30일 무료 사용 (계정당 1회, 1개의 plan만 가능)",
    "plan_content_filter": "콘텐츠 사전검수",
    "plan_open_platform": "오픈가능 채널 수",
    "plan_upload_count": "업로드 횟수",
    "plan_upload_limit_free_1": "주 1회",
    "plan_upload_limit_free_2": "Global PASS인증 시 ",
    "plan_upload_limit_free_3": "주2회",
    "plan_upload_limit_basic": "플랫폼별 일 1회",
    "plan_upload_limit_pro": "무제한",
    "plan_upload_limit_pro_a": "(플랫폼별 일 최대 5회)",
    "plan_upload_store": "1회 업로드 용량 제한",
    "plan_copyright": "저작권 보호(불펌 신고)",
    "plan_charge": "정산 수수료",
    "plan_storage": "제공 스토리지",
    "plan_profitability": "수익화 인증",
    "plan_account": "채널 소유권 인증",
    "plan_chinese_title": "중문 제목 최적화",
    "plan_cover": "썸네일 최적화",
    "plan_tag": "해시태그 최적화",
    "plan_appointment": "예약 업로드",
    "plan_upload_simul": "각 플랫폼 동시 업로드",
    "plan_plus_v": "채널 v 인증 제공",
    "content_4_5": "point 사용 규칙",
    "content_4_5_1": "adobaro 서비스의 이용 중 특정 조건에 부합하는 경우 point가 지급됩니다. 지급되는 point는 현금으로 환전할 수 없으며, 사이트에서 제공되는 일부 서비스에서 사용이 가능합니다.",
    "content_4_5_2": "지급되는 point는 adobaro의 운영 정책에 따라 변경될 수 있으며, 사용처 역시 조정될 수 있습니다.",
    "content_4_6": "유료 Plan의 해지와 환불 규정",
    "content_4_6_1": "사용자는 언제든지 유료 plan을 해지할 수 있습니다. 유료 plan을 해지하면 plan의 자동 갱신이 사용 중지됩니다.",
    "content_4_6_2": "유료 plan을 해지하면 요금이 다시 청구되지 않으며 결제 주기 마지막 날까지 유료 plan의 혜택이 유지됩니다.",
    "content_4_6_3": "유료 plan을 해지하는 시점과 유료 plan이 종료되는 시점 사이의 기간에 대해서는 환불되지 않습니다.",
    "content_4_6_4": "결제 후 14일이 지난 후 취소하는 경우 환불되지 않으며 서비스는 계약 기간이 끝날때까지 계속됩니다.",
    "content_4_6_5": "결제 후 14일이 지난 후부터 환불은 제공되지 않지만 해지 시 남은 유료 plan의 이용기간이 종료된 후, 더 이상 요금이 청구되거나 갱신되지 않습니다.",
    "content_5": "제3자 링크",
    "content_5_1": "adobaro 서비스는 기타 국제 인터넷 웹사이트 또는 리소스와 링크를 제공할 수 있습니다. 별도의 성명이 없는 이상, adobaro 는 제3자 웹사이트의 서비스를 통제할 수 없으므로 사용자의 상기 웹사이트 또는 리소스를 이용함으로 인해 발생하는 손실이나 손해에 대하여 adobaro 는 어떠한 책임도 부담하지 않습니다. 따라서 adobaro 를 떠나기 전, 기타 웹사이트 또는 리소스 방문 전 해당 서비스 조항 및 개인정보",
    "content_6": "지적재산권",
    "content_6_1": "국제 라이선스 공약, 중화인민공화국 저작권법, 특허법 및 기타 지적재산권 관련 법규에 의거, adobaro 서비스 및 본 서비스에 사용하는 소프트웨어, 기술, 상표, 소재 등 모든 지적재산권은 adobaro 가 소유합니다. '지적재산권'은 특허법, 저작권법, 상표법, 불공정경쟁 방지법 등 법률에 규정된 소유권리, 소유권 및 이익, 그리고 그 중 모든 적용, 갱신, 확장, 복구를 포함합니다.",
    "content_6_2": "사용자는 adobaro 서비스에서 사용하는 소프트웨어, 기술, 소재 등을 수정, 편집, 번역하거나 이에 관한 파생 작품을 창작할 수 없으며, 역공학, 역편집, 리버스 어셈블리 또는 기타 유사 행위를 통해 소스 코드를 취득할 수 없습니다. 이로 인해 발생하는 모든 법적 책임은 사용자가 부담하며 adobaro 는 적법하게 위약에 대한 법적 책임을 물을 수 있습니다.",
    "content_6_3": "사용자는 adobaro 서비스에서 사용하는 소프트웨어, 기술, 소재 등을 악의적으로 수정, 복제, 전파할 수 없습니다. 이로 인해 타인에게 손해가 발생하거나 adobaro 회사 이미지에 손상이 발생한 경우 귀하는 이에 합당한 법적 책임을 부담해야 합니다.",
    "content_6_4": "사용자는 adobaro 의 라이선스 성명, 상표 또는 기타 권리 성명을 임의로 삭제, 마스킹, 수정할 수 없습니다. adobaro 에서 디자인한 패턴 및 기타 패턴, 제품 및 서비스 명칭은 전부 adobaro 가 소유하는 상표, 로고에 속합니다. 사용, 복제 또는 기타 용도로의 사용을 금합니다.",
    "content_6_5": "adobaro 는 자체 제작 콘텐츠 및 기타 라이선스를 취득하여 독점하는 콘텐츠에 대하여 완전한 지적재산권을 소유하며, adobaro 의 허가 없이는 그 어떤 기관이나 개인도 사적으로 게시, 전파 또는 스트리밍을 제공하여 adobaro 의 지적재산권을 침해할 수 없습니다. 이를 위반한 경우 adobaro 는 권익 침해 행위자에게 법적 책임을 추궁할 수 있습니다.",
    "content_6_6": "adobaro 가 소유하는 지적재산권은 사용자의 사용 행위로 인한 특허 이전이 발생하지 않습니다.",
    "content_7": "면책 조항",
    "content_7_1": "adobaro 는 모든 포함, 경유, 링크, 다운로드 또는 관련 웹서비스를 통해 취득한 콘텐츠, 정보 또는 광고에 대하여 정확성과 신뢰성을 보장하지 않습니다; 사용자가 본 서비스를 통해 진행하는 홍보와 게시를 통해 성립된 매매 또는 제품, 정도 또는 자료의 취득에 대하여 adobaro 는 보증의 책임을 지지 아니합니다. 사용자는 본 서비스의 위험을 스스로 부담합니다.",
    "content_7_2": "별도의 명확한 서명 규정이 없는 한, adobaro 에서 귀하에게 제공하는 모든 제품과 서비스는 '현재 상태'와 '현존'을 전제로 제공됩니다.",
    "content_7_3": "adobaro 는 다음 사항을 보장하지 않습니다(다음을 포함하되 이에 국한하지 않음):",
    "content_7_3_1": "adobaro 에서 제공하는 웹사이트, 고객 단말 등 소프트웨어가 비록 adobaro 테스트를 거쳤으나, 기술 자체의 한계로 인해 adobaro 는 기타 소프트웨어, 하드웨어, 시스템과의 완전한 호환성을 보장할 수 없습니다. 호환 문제가 발생할 경우 사용자는 이를 adobaro 에 신고하고 기술지원을 받을 수 있습니다. 문제가 해결되지 아니할 경우, 사용자는 adobaro 서비스를 언로드하거나 사용을 중단할 수 있습니다.",
    "content_7_3_2": "adobaro 서비스에는 Internet 서비스가 관련되므로 다양한 부분에서 불안정한 요소의 영향을 받을 수 있습니다. 불가항력적 사건, 해커 공격, 시스템 불안정, 네트워크 중단, 사용자 전원 차단, 통신 선로 등 요인으로 인해 adobaro 서비스가 중단되거나 고객의 수요를 만족하지 못하는 상황이 발생할 수 있습니다. adobaro 는 adobaro 서비스가 고객의 사용 요건을 충족할 수 있음을 보장하지 않습니다.",
    "content_7_3_3": "adobaro 에서 제공하는 고객 단말 소프트웨어는 네트워크 경로로 다운로드, 전송할 수 있으므로, adobaro 지정 공식 다운로드가 아니거나, adobaro 가 가정한 경로가 아닌 곳에서 adobaro 관련 소프트웨어를 다운로드하는 경우 adobaro 는 바이러스 감염 여부, 트로이 감염 여부 등 해킹 소프트웨어로부터의 안전성을 보장할 수 없으며, 고객이 이로 인해 입게 되는 모든 직접 또는 간접 손실에 대해 배상 등 법적 책임을 부담하지 아니합니다.",
    "content_7_3_4": "adobaro 는 adobaro 서비스, 제품의 안전성, 신뢰성, 즉시성 및 성능에 관한 보장을 제공하지 않습니다.",
    "content_7_3_5": "adobaro 에서 제공하는 모든 제품, 서비스 또는 기타 소재가 사용자의 기대에 부합함을 보장하지 않습니다.",
    "content_7_4": "사용자가 adobaro 서비스를 거치지 않고 다운로드 또는 취득한 모든 자료로 인한 위험은 사용자가 스스로 부담하며, 이로 인해 사용자의 컴퓨터 시스템이 파괴되거나 자료가 유실된 경우 모든 책임은 사용자가 부담합니다.",
    "content_7_5": "다음 사유로 발생한 이윤, 영업이익, 자료의 손실 또는 기타 유형 또는 무형의 손실에 대하여 adobaro 는 그 어떠한 직접, 간접, 부수, 파생 또는 징벌적 배상도 제공하지 않습니다:",
    "content_7_5_1": "adobaro 서비스 전체 또는 일부를 사용할 수 없음;",
    "content_7_5_2": "adobaro 서비스를 통해 구매하거나 취득한 제품, 자료 또는 서비스;",
    "content_7_5_3": "사용자 자료가 허가 없이 사용 또는 수정;",
    "content_7_5_4": "기타 adobaro 서비스 관련 사항.",
    "content_7_6": "사용자는 반드시 자신의 계정과 비밀번호를 적절히 관리하고 비밀번호 보안성을 강화하여 계정 유출이나 도용을 막아야 합니다. 사용자 계정 유출 또는 도용으로 인해 발생한 손실에 대하여 adobaro 는 보상의 책임을 지지 않습니다. 전신 및 네트워크 통신 부분의 통신 선로 고장, 네트워크 또는 컴퓨터 고장, 시스템 불안정, 불가항력(서버 다운 등) 등 adobaro 가 아닌 사유로 사용자 계정, 계정 내 자산 유실, 감소가 발생한 경우, adobaro 는 보상의 책임을 지지 않습니다.",
    "content_7_7": "사용자는 자발적으로 무료 다운로드 및 adobaro 서비스 이용을 선택하였으므로 위험은 스스로 부담합니다. 이는 사용자가 adobaro 서비스를 이용하는 과정에서의 모든 행위 및 adobaro 서비스 이용으로 인해 발생하는 모든 불이익을 포함하되 이에 국한하지 않습니다. adobaro 서비스 다운로드 및 사용으로 인해 컴퓨터 시스템에 손상이나 데이터 유실이 발생한 경우, 모든 책임은 사용자가 스스로 부담합니다.",
    "content_7_8": "사용자의 인증 정보가 허위임으로 인해 계정, 계정 내 자산 유실, 감소가 발생하여 복구가 불가한 경우 adobaro 는 그 어떠한 법적 책임도 부담하지 않습니다.",
    "content_7_9": "adobaro 관련 플랫폼 서비스가 시스템 유지보수 또는 업그레이드 등의 필요로 잠시 중단되는 경우 사전에 이를 공지합니다. 하드웨어 고장 또는 기타 불가항력적 사유로 서비스 중단이 발생한 경우, 서비스 중단 기간 발생한 모든 불편과 손실에 대하여 adobaro 는 그 어떠한 책임도 부담하지 않습니다. adobaro 의 조정으로 인해 정보 유실 및/또는 기타 결과가 초래된 경우, adobaro 는 그 어떠한 책임도 부담하지 않습니다. 중국 플랫폼이 서비스를 중단하는 경우, adobaro 는 해당 플랫폼에서 사전에 통지한 내용을 즉시 귀하에게 통보합니다. 중국 플랫폼 시스템 중단으로 인해 초래된 불편 또는 손실에 대하여 adobaro 는 그 어떠한 책임도 부담하지 않습니다.",
    "content_8": "서비스 변경, 중단, 종료",
    "content_8_1": "귀하는 adobaro 가 경영 전략에 따른 조정으로 인해 서비스 콘텐츠에 변경이 발생하거나 서비스 중단, 종료가 발생할 수도 있음을 인지하고 이에 동의합니다.",
    "content_8_2": "adobaro 에 합병, 분리, 인수, 자산 양도가 발생할 경우, adobaro 는 제3자에게 본 서비스 관련 자산을 양도할 수 있음을 인지하고 이에 동의합니다; adobaro 는 또한 일방적으로 귀하에게 통보 후 본 계약의 일부 또는 전부 서비스 및 이에 상응하는 권리와 의무를 제3자에게 양도하여 운영 또는 이행할 수 있음을 인지하고 이에 동의합니다.",
    "content_8_3": "adobaro 서비스 이용 시 위법하거나 본 계약 및 기타 adobaro 규정, 사회 공공질서와 미풍양속에 위배되고/또는 타인의 합법적 권익을 침해하는 경우, adobaro 는 별도의 통보없이 일방적으로 귀하에게 제공하는 일부 또는 전체 서비스를 중단하거나 종료할 수 있음을 인지하고 이에 동의합니다.",
    "content_8_4": "귀하가 법률, 공공질서, 미풍양속을 위반하고/또는 타인의 합법적 권익을 침해하는 행위를 한 경우 이로 인해 adobaro 및/또는 타인의 명예, 명성 또는 기타 합법적 권익에 악영향을 미쳤다면 adobaro 는 별도의 통보없이 일방적으로 귀하에게 제공하는 일부 또는 전체 서비스를 중단하거나 종료할 수 있음을 인지하고 이에 동의합니다.",
    "content_8_5": "adobaro 는 귀하에게 서비스를 종료한 뒤, 적용 법률 요건에 따라 귀하의 개인정보를 삭제하거나 익명화할 수 있으며, 적법한 기간과 방식으로 귀하가 플랫폼에 남긴 기타 콘텐츠와 정보를 보관할 수 있습니다.",
    "content_9": "탈퇴",
    "content_9_1": "사용자는 adobaro 에 계정 탈퇴 신청을 제출할 수 있고, adobaro 고객센터에서 계정(법적으로 본 계약 또는 기타 adobaro 규정에 별도 약정된 경우 제외)을 탈퇴할 수 있습니다.",
    "content_9_2": "유의사항: adobaro 계정 탈퇴 후에는 동일한 계정으로 adobaro 의 모든 제품과 서비스 및 제품과 서비스 중 제3자와 협력하는 서비스 콘텐츠를 등록, 이용할 수 없으며, adobaro 역시 해당 계정으로 귀하에게 제공하는 각종 제품과 서비스를 중단합니다. 이는 사용자의 사후관리 및 권익 보호에 불편을 야기할 수 있습니다. adobaro 계정 탈퇴 후에는 복구가 불가능합니다. 따라서 탈퇴 전 신중하게 고려해야 합니다.",
    "content_9_3": "adobaro 계정을 탈퇴할 경우, 귀하는 계정 탈퇴 후에도 해당 UID 가 여전히 존재하지만 해당 계정과 관련된 다음의 권익을 누리지 못하게 됨을 인지하고 이에 동의합니다:",
    "content_9_3_1": "계정 탈퇴 후에는 해당 계정으로 재등록하거나 adobaro 의 모든 제품과 서비스를 이용할 수 없습니다;",
    "content_9_3_2": "계정 탈퇴 후에는 해당 계정으로 등록, 사용하던 adobaro 의 모든 제품과 서비스 내 코든 콘텐츠, 정보, 데이터, 히스토리가 전부 삭제 또는 익명화 되며, 검색, 방문, 취득, 사용, 복구가 불가합니다. 이는 다음을 포함하되 이에 국한하지 않습니다: 플랫폼을 개통했던 계정 정보(사진, 별명, 서명 등), 바인딩 정보;",
    "content_9_3_3": "계정 탈퇴 후, 해당 계정의 거래 기록은 삭제되어 복구 불가능합니다.",
    "content_9_3_4": "계정 탈퇴 후, adobaro 는 더 이상 사용자에게 그 어떠한 계정 관련 서비스도 제공하지 아니합니다;",
    "content_9_3_5": "귀하는 계정 탈퇴를 통해 해당 계정의 adobaro 제품 및 서비스 사용 기간에 생성하였으나 소비를 완료하지 못한 권익 및 장래 예상 이익을 포기하는 데 동의합니다. adobaro 는 해당 계정의 전체 권익을 삭제할 수 있으며 이는 다음을 포함하되 이에 국한하지 않습니다: 만료되지 않은 회원권, 미사용 쿠폰, 게임 캐릭터의 사이버 화폐 및 툴, adobaro 각 제품 및/또는 서비스 내 각종 신분상 권익, adobaro 각 제품 및/또는 서비스에서 이미 구매하여 기한이 만료되지 아니한 온라인 서비스 콘텐츠, 기타 이미 생성하였으나 소비를 완료하지 못한 권익 또는 장래 예상 이익.",
    "content_9_4": "adobaro 계정 탈퇴 신청 전, 귀하의 계정 보안 및 재산권익을 보호하기 위해 반드시 탈퇴를 신청할 계정이 다음 조건을 충족하는지 확인해야 합니다. 이는 다음을 포함하되 이에 국한하지 않습니다:",
    "content_9_4_1": "계정 사용자가 공식 채널을 통해 등록하고 본 계약 및 관련 규정에 부합하는 계정일 것; 또한 사용자 본인의 회원 계정일 것;",
    "content_9_4_2": "고객센터 탈퇴 절차에 따라 탈퇴를 진행할 것;",
    "content_9_4_3": "계정이 보안 상태가 아닐 것: 비밀번호 찾기 신청상태, 휴대전화번호 수정 중이 아닐 것; 처리 중인 불만, 신고가 없을 것; 기타 안전/이상 상태가 아닐 것;",
    "content_9_4_4": "계정 내 처리 중인 거래가 없을 것;",
    "content_9_4_5": "계정 내에 사용자가 신청하였으나 인출이 완료되지 않은 거래가 없을 것;",
    "content_9_4_6": "계정과 adobaro 전체 업무에 계약 관계 또는 아직 계약 기간인(메인 사회자 계약 등) 사안이 없을 것;",
    "content_9_4_7": "계정이 기타 서비스를 개통했던 경우 해당 서비스를 스스로 취소할 것;",
    "content_9_4_8": "기타 필요한 조건.",
    "content_9_5": "귀하가 9.2 와 9.3 조항을 충분히 숙지 및 이해하고 9.4 조항의 모든 조건을 충족한 경우 고객센터에 계정 탈퇴 신청을 할 수 있습니다. 고객센터는 귀하의 계정 보안상태 및 귀하의 관련 제품과 서비스 사용 상황을 심사한 뒤 귀하의 계정이 조건에 부합하는지 종합적으로 판단합니다.",
    "content_9_6": "adobaro 계정을 탈퇴하면 귀하와 체결했던 관련 사용자 계약, 기타 권리 및 의무 등이 종료(지속적으로 효력이 유지되도록 약정하였거나 법적으로 별도 규정된 부분 제외)됩니다. 동시에, 귀하는 다음을 인지하고 이에 동의합니다: 귀하의 계정이 탈퇴되었더라도 계약기간 내 관련 법률, 관련 계약, 규칙 등에 따라 부담해야 할(수도 있는) 책임은 면제되거나 감면되지 아니합니다. 또한, 귀하 계정 탈퇴 후, 귀하가 adobaro 에서 사용하는 휴대전화번호로 개통했던 모든 중국 플랫폼 계정이 전부 탈퇴되므로 adobaro 를 통해 중국 플랫폼으로 귀하의 콘텐츠를 배포할 수 없으며, 탈퇴를 완료한 시점부터 adobaro 내에서의 모든 귀하 수익도 인출할 수 없습니다. 이로 인해 발생하는 adobaro 및 플랫폼 계정의 수익 손실은 귀하가 부담하며 adobaro 는 귀하가 계정을 탈퇴함으로써 그 어떠한 배상이나 반환의 의무도 부담하지 아니합니다.",
    "content_10": "개인정보 정책",
    "content_10_1": "adobaro 는 사용자의 개인정보 및 사생활을 중시합니다. 개인정보란, 전자 또는 기타 방식으로 단독 또는 기타 정보와 함께 특정 자연인의 신분 또는 특정 자연인의 활동 상황을 식별할 수 있는 각종 정보를 말합니다. 귀하가 adobaro 제품 및/또는 서비스를 다운로드, 설치, 구동, 검색, 등록, 가입, 사용할 때, adobaro 는 플랫폼에 공지된 <adobaro 개인정보 정책>의 약정에 따라 귀하의 개인정보를 처리하고 보호합니다. 따라서 귀하는 <adobaro 개인정보 정책>을 꼼꼼히 읽고 필요 시 <adobaro 개인정보 정책>에 따라 필요한 적절한 선택을 해야 합니다.",
    "content_10_2": "귀하는 <adobaro 개인정보 정책>을 숙지하고 충분히 이해한 뒤 adobaro 제품 및/또는 서비스를 사용해야 합니다. 정책 내용에 동의하지 아니할 경우 adobaro 제품 및/또는 서비스의 정상 사용에 제약을 받거나 adobaro 가 추구하는 서비스 효과를 누리지 못할 수 있습니다. adobaro 가 제공하는 제품 및/또는 서비스를 지속적으로 사용하는 행위는 귀하가 <adobaro 개인정보 정책>(업데이트 버전 포함)을 충분히 이해하고 동의하였음을 의미하는 것으로 간주합니다.",
    "content_10_3": "귀하가 <adobaro 개인정보 정책> 또는 귀하의 개인정보 관련 내용에 대하여 문의(질문, 신고 등 포함)가 있을 경우, <adobaro 개인정보 정책>에 공지된 연락처로 연락할 수 있습니다.",
    "content_11": "미성년자 조항",
    "content_11_1": "adobaro 는 미성년자 보호에 힘씁니다. 귀하가 미성년자인 경우 반드시 법적 보호자의 관리 감독 하에 본 계약을 읽고, 법정 보호자의 동의를 얻은 뒤 adobaro 제품 및/또는 서비스를 사용할 수 있습니다.",
    "content_11_2": "법정 보호자는 자녀의 인터넷 사용상 안전 문제에 충분히 주의하여 각종 위험을 미연에 방지해야 합니다. adobaro 는 미성년자의 adobaro 를 사용한 제품/서비스 소비를 권장하지 않으며, 만약 소비하는 경우 미성년자는 반드시 법정 보호자의 명의로 소비를 신청하거나 법정 보호자의 명시적 동의 하에 이를 진행해야 합니다. 미성년자가 adobaro 서비스를 이용하는 행위는 법정 보호자의 허가를 받은 것으로 간주합니다.",
    "content_11_3": "adobaro 는 미성년자가 adobaro 서비스를 이용할 경우 온라인 학습에 도움이 되고 인터넷과 현실의 구분을 명확히 하며 과도하게 인터넷에 심취하여 일상 학습에 영향을 미치지 않도록 주의해야 합니다.",
    "content_11_4": "미성년자의 개인정보 보호에 관하여 adobaro 는 플랫폼에 공지된 <adobaro 개인정보 정책>의 설명과 방법을 엄격하게 준수하여 이행합니다.",
    "content_12": "법률의 적용 및 관할권",
    "content_12_1": "본 계약의 효력 발생, 이행, 해석 및 분쟁의 해결은 일괄 중화인민공화국의 법률을 따릅니다. 본 조항에서 중화인민공화국 현행 법률에 저촉되어 계약의 일부 무효하게 되더라도, 기타 부분의 효력에 영향을 미치지 아니합니다. 양 당사자는 분쟁 발생 시 최신 버전의 <adobaro 사용자 사용 계약서>를 기준으로 하는데 동의합니다.",
    "content_12_2": "만일 본 계약의 내용 또는 계약의 이행에 대하여 분쟁이 발생한 경우, 우호적인 협의를 최우선으로 합니다; 협의가 이루어지지 아니할 경우 각 당사자는 해당 사안을 adobaro 중재위원회에 회부하여 규정에 따라 중재하는데 동의합니다. 중재 판결은 1심으로 종결하며 각 당사자에게 법적 구속력을 갖습니다.",
    "content_12_3": "논쟁을 피하기 위해, 반대되는 서면 약정이 존재하지 않는 한, 본 조항의 법률 적용과 관할 조건의 효력은 본 계약과 기타 adobaro 규칙에 전부 적용되며, 수정, 보충, 갱신을 전부 포함합니다.",
    "content_13": "계약의 수정 및 통보",
    "content_13_1": "adobaro 는 국가 정책, 기술 조건, 제품 성능 등의 변화에 따라 본 계약을 수정할 수 있고, 수정한 계약서를 배포해야 합니다.",
    "content_13_2": "전술한 내용이 배포되면 adobaro 는 적절한 방식(팝업, 우편, 알림, 웹사이트 공지 등을 포함하되 이에 국한하지 않음)으로 귀하에게 갱신 내용을 알려 귀하가 즉시 본 계약의 최신 버전을 인지하도록 합니다.",
    "content_13_3": "본 계약의 명칭, 챕터 제목 등은 열람 편의를 위해 만들어진 것이며 본 계약은 플랫폼에 비교적 많이 게시되므로 모든 계약의 명칭을 전부 변경하려면 일정 시간이 소요됩니다. 해당 기간 내 2개의 명칭이 동시에 존재하더라도 본문의 모든 조항의 의미와 해석에 영향을 미치지 아니합니다.",
    "content_13_4": "수정된 내용은 본 계약과 불가분의 일부가 되므로 귀하는 동등하게 준수해야 합니다. 수정된 계약서에 대해 의견이 있을 경우 즉시 adobaro 및 관련 서비스 등록 및 사용을 중지해야 하며, 지속적으로 adobaro 및 관련 서비스를 이용하거나 등록할 경우, 귀하가 이미 갱신된 계약을 충분히 인지하고 이해했으며 본 계약의 구속을 수용하였음으로 간주합니다.",
    "content_13_5": "본 사용자 계약서의 내용은 기타 언어로 번역되어 참고용으로 제공합니다. 번역본과 본 사용자 계약의 원본이 불일치할 경우 반드시 중문 버전을 기준으로 합니다."
  },
  "help": {
    "title": "adobaro 도움말 센터",
    "update_date": "문서 업데이트: 2023년 8월 22일 ver.02",
    "start": "시작하기 위해 반드시 읽어야 하는 것",
    "adobaro": "아도바로란?",
    "adobaro_content": "아도바로는 크리에이터 누구나 쉽고 간편하게 더 많은 플랫폼에서 새로운 팬들을 만날 수 있는 Creator Content Cross-Border SaaS Solution 입니다. 아도바로는 당신이 콘텐츠 창작에 더욱 집중할 수 있도록 복잡한 채널 개설 및 인증, 관리, 수익정산 등의 모든 과정을 쉽고 빠르게 만들어 드립니다. 아도바로를 통해 국경을 넘어 다양한 플랫폼에 진출해보세요.",
    "plan_title": "각 Plan의 혜택",
    "plan_subtitle": "각 plan별 혜택은 아래와 같습니다.",
    "plan_project": "항목",
    "plan_free": "Free",
    "plan_basic": "Basic",
    "plan_pro": "Pro",
    "plan_original": "정가",
    "plan_price": "가격",
    "plan_discounts": "할인률",
    "plan_discounts_prices": "할인된 가격",
    "plan_special_offer": "프로모션",
    "plan_month": "월 구독",
    "plan_year": "년 구독",
    "plan_special_des": "가입 시 30일 무료 사용 (계정당 1회, 1개의 plan만 가능)",
    "plan_content_filter": "콘텐츠 사전검수",
    "plan_open_platform": "오픈가능 채널 수",
    "plan_upload_count": "업로드 횟수",
    "plan_upload_limit_free_1": "주 1회",
    "plan_upload_limit_free_2": "Global PASS인증 시 ",
    "plan_upload_limit_free_3": "주2회",
    "plan_upload_limit_basic": "플랫폼별 일 1회",
    "plan_upload_limit_pro": "무제한",
    "plan_upload_limit_pro_a": "(플랫폼별 일 최대 5회)",
    "plan_upload_store": "1회 업로드 용량 제한",
    "plan_copyright": "저작권 보호(불펌 신고)",
    "plan_charge": "정산 수수료",
    "plan_storage": "제공 스토리지",
    "plan_profitability": "수익화 인증",
    "plan_account": "채널 소유권 인증",
    "plan_chinese_title": "중문 제목 최적화",
    "plan_cover": "썸네일 최적화",
    "plan_tag": "해시태그 최적화",
    "plan_appointment": "예약 업로드",
    "plan_upload_simul": "각 플랫폼 동시 업로드",
    "plan_plus_v": "채널 v 인증 제공",
    "plan_tips": "각 plan의 혜택은 내부 정책에 따라 변경되거나 추가될 수 있습니다.",
    "points_title": "adobaro points란?",
    "points_des": "adobaro points란 유저의 활동량에 따라 혜택이 커질 수 있도록 준비한 보너스 개념의 포인트입니다. 포인트는 환전이나 출금을 할 수 없으며, 포인트샵을 통해 채널 운영에 필요한 유료 아이템들을 포인트를 이용하여 구매하실 수 있습니다.",
    "points_pay_project": "포인트 지급 항목",
    "points_vip": "회원 가입",
    "points_pass": "PASS 인증",
    "points_open_platform": "플랫폼 개통",
    "points_upload": "업로드",
    "points_additional": "4개 미션 달성 시 추가",
    "points_invite": "친구 초대",
    "points_upload_once": "콘텐츠 업로드 1회",
    "points_invite_des": "※ 4개 미션 달성 시 100p 추가 (총 500p)",
    "points_tips_1": "업로드 시 지급되는 포인트는 업로드되는 플랫폼 갯수와 상관없이 1회 업로드 기준입니다.",
    "points_tips_2": "Marketplace는 곧 선보일 예정입니다. ",
    "beginner": "신규 유저 가이드",
    "menu": "메뉴 소개",
    "upload": "업로드",
    "upload_des": "기존에 업로드했던 혹은 신규 콘텐츠를 adoba.AI가 제목과 소개글을 현지 언어에 맞게 보정해 주며, 각각의 플랫폼에 맞는 썸네일을 자동 생성하여, 최적화된 콘텐츠로 업로드됩니다.",
    "material": "파일 드라이브",
    "material_des": "업로드한 동영상, 썸네일 등 원본 콘텐츠를 관리하는 스토리지 공간입니다.",
    "content": "콘텐츠 관리",
    "content_des": "각 플랫폼에 업로드된 콘텐츠의 데이터 열람, 수정 및 삭제 등의 관리를 합니다.",
    "platform": "플랫폼 소개",
    "platform_des": "개통한 플랫폼 채널들의 데이터 열람 및 관리를 합니다.",
    "wallet": "내 지갑",
    "wallet_des": "크리에이터 활동을 통해 얻은 수익과 point를 확인 할 수 있습니다.",
    "pass": "PASS 인증센터",
    "pass_des": "adobaro 사용자가 더욱 더 안전하고 빠르게 운영을 할 수 있도록 돕는 보안 인증센터입니다. ",
    "setting": "설정",
    "setting_des": "아도바로 계정에 관한 설정합니다",
    "code": "휴대폰 인증번호",
    "code_tip_1": "중국 플랫폼을 이용하려면 휴대폰 번호가 필요합니다, 휴대폰 번호는 개인전용 번호이므로, 보안이 훨씬 높습니다.",
    "code_tip_2": "플랫폼 개통 시 두 단계의 본인 인증이 진행이 됩니다.",
    "code_tip_3": "개통 신청 후, 이메일 혹은 본인 페이지에서 1차 본인 인증을 진행합니다.",
    "code_tip_3_des": "이메일을 받지 못했다면, 스팸메일함에 들어갔는지 한번 확인해주셔야 합니다. 이메일 수신은 보통 빠르게 수신 가능하지만, 메일 서버간의 전송 시간으로 인해 3-5분 정도 딜레이가 될 수 있습니다.",
    "code_tip_4": "1차 본인 인증 후, 2차 본인 인증을 위한 본인 휴대폰에 플랫폼 인증 번호가 발송됩니다.",
    "code_tip_5": "수신 받은 인증번호를 아도바로에 입력하면 플랫폼 개통 신청이 완료됩니다.",
    "channel": "@채널 아이디는 어디서 볼 수 있나요?",
    "youtube_link": "Youtube 링크 예시",
    "youtube_link_des": "채널 세팅에 따라 링크 창에 보이는 주소가 다르므로, 예시와 다를 경우 아래와 같이 채널 아이디를 찾아주세요.",
    "channel_id": "채널 아이디 찾는 방법",
    "channel_id_tip_1": "YouTube 모바일 앱 혹은 웹 버젼을 열어주세요.",
    "channel_id_tip_2": "우측 상단의 프로필 사진을 누르면 채널명과 함께 @로 표시된 채널 아이디가 나타납니다.",
    "channel_id_tip_3": "@로 표시된 채널 아이디를 아도바로 주소창에 입력 및 붙여넣기를 해주세요. ",
    "tiktok_link": "TikTok 링크 예시",
    "tiktok_link_des_1": "링크 창 @ 이후의 주소는 채널명과 동일합니다.",
    "tiktok_link_des_2": "채널명을 아도바로 주소창에 입력 혹은 붙여넣기를 해주세요. ",
    "bilibili": "비리비리",
    "alias": "별칭",
    "users": "사용자층",
    "active_users": "월간 활성 이용자수",
    "active_creator": "활동중인 크리에이터",
    "video_type": "영상 형식",
    "view": "조회수 수익",
    "specialty": "특징",
    "bilibili_alias": "중국판 유튜브 (별칭: B站)",
    "bilibili_users": "18-35세의  MZ세대: 80%",
    "bilibili_active_users": "3.15억명",
    "bilibili_active_creator": "380만명",
    "bilibili_video_type": "가로(주)+세로",
    "bilibili_view": "팬 1,000명 부터 수익발생 (조회수 1만: 3,000~6,000원)",
    "bilibili_specialty_1": "*젊은층의 이용자가 압도적으로 많은 플랫폼 : 24세 이하: 75% [2015년 기준]",
    "bilibili_specialty_2": "*탄막(弹幕-날아다니는 자막)기능 사용: 사이버 그룹형 시청 분위기  조성",
    "bilibili_specialty_3": "*버추얼 크리에이터가 활동하기에 좋은 환경",
    "bilibili_specialty_4": "*메타버스에 적극적인 특징",
    "xigua": "시과 비디오",
    "xigua_alias": "중국판 유튜브",
    "xigua_users": "25-35세: 45%를 차지",
    "xigua_users_percent": "남성&여성의 비율 비슷 (54:46)",
    "xigua_active_users": "1.8억명",
    "xigua_active_creator": "320만명",
    "xigua_video_type": "가로",
    "xigua_view": "누적 조회수 17,000회 이상시 수익발생 (조회수 1만: 3,000~10,000원)",
    "xigua_specialty_1": "*유튜브처럼 보편적인 성향의 플랫폼을 지향",
    "xigua_specialty_2": "*바이트댄스의 핵심 역량인 개인 맞춤형 콘텐츠 추천 알고리즘을 도입",
    "xigua_specialty_3": "*넓은 사용자층과 다양한 콘텐츠 영역 확보",
    "xigua_specialty_4": "*브이로그와 3농(농업, 농촌, 농민) 분야의 콘텐츠가 더 선호되는 특징",
    "xigua_specialty_5": "*도우인과 함께 필수적으로 운영해야할 플랫폼 : 하나의 콘텐츠로도 트래픽의 급격한 증폭효과",
    "haokan": "하오칸 비디오",
    "haokan_alias": "중국판 네이버",
    "haokan_users": "26-35세",
    "haokan_active_users": "1.1억명 ",
    "haokan_active_creator": "200만명 이상",
    "haokan_video_type": "가로",
    "haokan_view": "조회수 1만:  1,500์~2,000원",
    "haokan_specialty_1": "*중국의 최대 검색엔진인 바이두(百度)의 대표적인 중편 영상 플랫폼",
    "haokan_specialty_2": "*정부의 영향을 많이 받는 편이라 비교적 보수적인 운영정책을 시행",
    "haokan_specialty_3": "*채널 자체의 성과보다는 브랜드 홍보 효과&검색결과 확보를 위해 활용한다면 굿굿굿!",
    "aiqiyi": "아이치이",
    "aiqiyi_alias": "중국판 넷플릭스",
    "aiqiyi_users": "다양한 연령대 ",
    "aiqiyi_active_users": "5.4억명",
    "aiqiyi_active_creator": "300만명 이상",
    "aiqiyi_video_type": "가로",
    "aiqiyi_view": "조회수 1만: 3,000์~4,000원",
    "aiqiyi_specialty_1": "*중국의 최대 검색엔진인 바이두(百度)의 동영상 웹사이트 플랫폼 ",
    "aiqiyi_specialty_2": "*드라마,버라이어티쇼 등 글로벌 콘텐츠 제작사 ",
    "aiqiyi_specialty_3": "*중국에서 트래픽이 가장 높은 모바일 App Top10에 선정",
    "aiqiyi_specialty_4": "*일일 평균 구독 회원수: 9,700만",
    "aiqiyi_specialty_5": "*전세계에서 넷플릭스 다음으로 비디오 서비스 한정 가장 많은 유료 구독자 보유 ",
    "kuaishou": "콰이쇼우",
    "kuaishou_alias": "중국판 틱톡",
    "kuaishou_users": "18세 이하의 10대 비율이 높음",
    "kuaishou_active_users": "6억명",
    "kuaishou_active_creator": "200만명 이상",
    "kuaishou_video_type": "세로(주)+가로",
    "kuaishou_view": "없음 ",
    "kuaishou_specialty_1": "*중국에서 틱톡 다음으로 가장 많은 사용자를 확보한 숏폼 동영상 플랫폼",
    "kuaishou_specialty_2": "*사용자들의 콘텐츠 특징: 느리고 서정적인 화면, 평범한 일상, 농촌 생활, 자연과 사람 등을 다룸",
    "kuaishou_specialty_3": "*저렴하고 질좋은 물건에 집중한 콰이쇼우의 라이브커머스 매출액이 큼",
    "kuaishou_specialty_4": "*최근 젊은 사용자가 점차적으로 유입되는 추세 평등하게 노출 기회 제공받음",
    "kuaishou_specialty_5": "-> 콘텐츠를 자주 올리는 게  중요!!!",
    "weibo": "웨이보",
    "weibo_alias": "중국판 트위터",
    "weibo_users": "24~34세: 80%",
    "weibo_active_users": "5.93억명",
    "weibo_active_creator": "40만명 이상",
    "weibo_video_type": "가로+세로",
    "weibo_view": "없음 ",
    "weibo_specialty_1": "*미니 블로그를 제공하는 소셜 미디어 플랫폼 ",
    "weibo_specialty_2": "*유명 연예인 등 인플러언서 많이 사용",
    "weibo_specialty_3": "*셀럽과 브랜드 마케팅이 활발함 ",
    "weibo_specialty_4": "*연예인 팬덤 문화 기반의 빠른 정보교환, 여론 이슈화 및 전파 ",
    "weibo_specialty_5": "*중국에 진출한 셀럽의 팬클럽과 브랜드 공식페이지는 웨이보에서 가장 먼저 시작됨",
    "weibo_specialty_6": "*웹 페이지, WAP페이지, 휴대폰 모바일 프로그램으로 게시물 게시",
    "weibo_specialty_7": "*사진, 동영상 또는 라이브 방송을 업로드하여 바로 공유 및 상호 작용",
    "redbook": "샤오홍슈",
    "redbook_alias": "중국판 인스타그램",
    "redbook_users": "18-34세의 여성(남성 사용자 비율 : 30%돌파)",
    "redbook_active_users": "2.6억명  ",
    "redbook_active_creator": "2,000만명",
    "redbook_video_type": "가로+세로",
    "redbook_view": "없음 ",
    "redbook_specialty_1": "*중국 온라인 마케팅의 대세 ",
    "redbook_specialty_2": "*요즘 중국에서 핫한 소셜네트워크(SNS)+전자상거래 플랫폼",
    "redbook_specialty_3": "*바둑판식 레이아웃으로 구성",
    "redbook_specialty_4": "*젊고, 지식이 풍부한 일명 왕홍(网红-판매왕)의 유전자를 가진 유저가 많음",
    "redbook_specialty_5": "*제품의 실구매 후기&리뷰가 중요한 콘텐츠 중 한 부분을 차지 ",
    "redbook_specialty_6": "*제품 사용리뷰&여행지 소개를 텍스트로 작성가능 ",
    "redbook_specialty_7": "*동영상과 라이브 방송 기능 결합",
    "redbook_specialty_8": "*사용후기 콘텐츠: 제품의 잠재 소비자들에게 어필&바이럴 효과까지 얻을 수 있음 ",
    "qqworld": "큐큐월드",
    "qqworld_alias": "중국판 카카오 스토리",
    "qqworld_users": "95년 이후 출생자 많음",
    "qqworld_active_users": "3.5억명  ",
    "qqworld_active_creator": "120만명 이상",
    "qqworld_video_type": "가로+세로",
    "qqworld_view": "없음 ",
    "qqworld_specialty_1": "*중국판 카카오톡(QQ)+(카카오)스토리 결합 형태의 숏폼 동영상 플랫폼 ",
    "qqworld_specialty_2": "*영상 업로드 외에 사진과 텍스트 업로드도 가능 ",
    "qqworld_specialty_3": "*사용층: 학생 등 젊은 연령대 많음 ",
    "platform_tip": "현재 더 많은 구독자들을 만날 수 있는 플랫폼들을 개척해 나가고 있습니다. 기대해주세요",
    "feature": "기능 소개",
    "feature_upload_1": "업로드는 기존 운영 플랫폼의 콘텐츠와 썸네일, 그리고 원어로 된 제목/소개글만 업로드 업로드 하시면, adoba.AI가 제목, 소개글을 현지 언어에 맞게 보정해 주며, 각각의 플랫폼에 맞는 썸네일을 자동 생성하여, 최적화된 콘텐츠로 업로드해주는 기능입니다.",
    "feature_upload_2": "아도바로는 한 번의 업로드로 여러 플랫폼에 동시다발적으로 업로드가 가능합니다.",
    "feature_upload_3": "업로드 가능 횟수는 각 plan에 따라 제공되는 내용이 달라집니다.",
    "upload_table_free": "Free",
    "upload_table_basic": "Basic",
    "upload_table_pro": "Pro",
    "upload_table_limit": "업로드 횟수",
    "upload_table_no_pass": "Pass 미인증",
    "upload_table_pass": "Pass 인증",
    "upload_table_limit_1": "주 1회",
    "upload_table_limit_2": "주 2회",
    "upload_table_limit_3": "플랫폼별 일 1회",
    "upload_table_limit_4": "무제한",
    "upload_table_limit_5": "(플랫폼별 일 최대 5회)",
    "feature_upload_4": "Free plan의 주간 업로드 횟수는 매주 월요일 0시 (GMT+09:00) 에 초기화가 됩니다.",
    "feature_upload_5": "업로드 횟수 제한은 왜 있는건가요?",
    "feature_upload_6": "각 플랫폼마다 정책이 다르지만, 기본적으로 생성된 지 얼마되지 않은 계정에서 많은 트래픽이 발생하면, 계정이 블럭되거나 제재가 있을 수 있습니다. 플랫폼사의 제재로부터 계정을 안전하게 보호하기 위한 수단으로 생각해 주세요.",
    "feature_upload_7": "그럼 Pro plan의 무제한이라는 표현은 왜 있는거죠?",
    "feature_upload_8": "  아도바로를 통해 업로드되는 콘텐츠들의 안전성을 보장받기 위해 각 플랫폼사와 협의 중입니다. 협의가 진행됨에 따라 업로드 제한 횟수가 늘어나거나 풀릴 수 있으며, 추후 변경되는 사항은 Pro plan에만 적용되어 업데이트될 예정입니다.",
    "feature_upload_9": "업로드하는데 왜 인증번호가 필요한가요?",
    "feature_upload_10": "  아도바로와 중국 플랫폼 연동 중, 비정기적으로 나타나는 본인 확인을 위한 필요한 보안 과정입니다. 초기에 조금 불편하시더라도 정보 보안을 위해 휴대폰 번호를 통한 인증을 부탁드립니다.",
    "feature_upload_11": "업로드된 콘텐츠는 파일 드라이브에 자동 저장됩니다.",
    "feature_upload_12": "아도바로의 편리한 사용을 위하여 업로드된 콘텐츠는 콘텐츠 심사와 무관하게 파일 드라이브 - 내 문서 내에 곧바로 저장이 됩니다.",
    "feature_material_1": "파일 드라이브에서 업로드된 콘텐츠를 쉽게 관리하세요",
    "feature_material_2": "파일 드라이브에 등록된 콘텐츠는 다른 플랫폼으로 추가 작업 없이 업로드를 하실 수 있습니다.",
    "feature_material_3": "파일 드라이브의 무료 용량은 초기 5GB가 제공되며, 선택하신 plan에 따라  제공되는 용량이 달라집니다.",
    "material_table_free": "Free",
    "material_table_basic": "Basic",
    "material_table_pro": "Pro",
    "material_table_storage": "스토리지 용량",
    "feature_material_4": "용량 초과 시 point를 통해 추가 용량 확장이 가능하도록 준비 중에 있습니다. ",
    "feature_content_1": "각 플랫폼에 업로드된 콘텐츠를 관리하고, 다른 플랫폼으로 추가 배포를 할 수 있는 기능입니다.",
    "feature_content_2": "콘텐츠 적합성 심사에 통과된 콘텐츠에 한해서만 콘텐츠 관리에서 열람이 가능합니다.",
    "feature_content_3": "업로드한 콘텐츠가 안보인다면?",
    "feature_content_4": "플랫폼으로 업로드하기 전, adoba.AI가 콘텐츠 적합성 심사를 진행합니다. 콘텐츠가 보이지 않는다면, 콘텐츠 적합성 심사에 통과되지 않아 업로드되지 않은 것으로 볼 수 있으며, 자세한 내용은 콘텐츠 적합성 심사 결과 이메일을 참고하시기 바랍니다.",
    "feature_content_5": "업로드된 콘텐츠는 파일 드라이브에 자동 저장이 됩니다.",
    "feature_content_6": "아도바로의 편리한 사용을 위하여 업로드된 콘텐츠는 콘텐츠 심사와 무관하게 파일 드라이브 - 내 문서 내에 곧바로 저장이 됩니다.",
    "global_pass": "Global PASS 인증",
    "feature_pass_1": "Global PASS는 아도바로를 통해 각 플랫폼에서 채널의 안전성을 보안하고, 수익활동을 시작할 수 있게 해 주는 본인 인증서입니다. Global PASS 인증을 받아야 플랫폼 개통이 가능하며, 플랫폼으로부터의 수익이 발생됩니다. ",
    "feature_pass_2": "Global PASS는 본인 인증을 위해 아래의 서류들이 진행됩니다.",
    "feature_pass_2_1": "여권 사진",
    "feature_pass_2_2": "여권 사진과 본인 셀카 사진",
    "feature_pass_2_3": "아도바로 위임 동의서",
    "feature_pass_3": "왜 여권이 필요한가요?",
    "feature_pass_4": "해외 여행을 가실 때, 여권을 가지고 가듯이 해외 플랫폼 운영시에도 본인 확인을 위한 여권이 사용됩니다. 여권 정보는 온전히 플랫폼 관련 운영을 위해서만 활용이 되며, 사용 내역은 우측상단의 이메일 - PASS 인증 센터 - 인증 사용 내역에서 확인 가능합니다.",
    "feature_pass_5": "왜 인증번호가 필요한가요?",
    "feature_pass_6": "여권은 소중한 정보이기에, 본인 확인을 위한 필요한 보안 과정입니다. 초기에 조금 불편하시더라도 정보 보안을 위해 휴대폰 번호를 통한 인증을 부탁드립니다.",
    "feature_pass_7": "Global Pass 인증이 완료되면, 다음과 같은 혜택이 추가됩니다.",
    "feature_pass_7_1": "플랫폼 수익화 조건 달성",
    "feature_pass_7_2": "주 2회 업로드 가능",
    "feature_pass_7_3": "통계 데이터 제공",
    "feature_pass_7_4": "플랫폼 개통 확장",
    "feature_pass_7_5": "저장 용량 증가",
    "feature_pass_7_6": "더 많은 혜택이 있습니다.",
    "feature_pass_8": "PASS 인증 후, 채널이 adoba MCN 으로 소속 되는 이유는 뭔가요?",
    "feature_pass_9": "  플랫폼에서 얻은 수익은 개인 채널 내 누적되며 해외 정산이 불가합니다. 단 adoba MCN 소속 채널이라면 각 플랫폼간의 파트너쉽을 통해 해외 정산이 가능합니다. 따라서 PASS 인증을 하시면 자동으로 acoba MCN 소속이 되며, 그와 동시에 플랫폼 수익 극대화 혜택을 제공받으실 수 있습니다.",
    "feature_pass_10": "Global PASS 인증 완료 후, 인증 카드가 생성이 되며, 인증 유효 기한은 신청일로부터 1년입니다.",
    "feature_pass_11": "인증 유효 기한이 지나면, Global PASS의 혜택이 더이상 제공되지 않습니다. 하지만 걱정마세요, 저희가 인증 유효 기간이 만료되기 전, 개인 알림을 보내드립니다. ",
    "customer_service": "고객센터",
    "customer_service_1": "저희 고객 서비스는 현재 {0} 통해 운영되고 있습니다.",
    "customer_service_1_1": "고객센터",
    "customer_service_2": "편리한 서비스 이용을 위해 고객센터를 업그레이드 하고 있으니, 불편하시더라도 양해부탁드리겠습니다."
  },
  "auth": {
    "modal_title": "adobaro에 오신걸 환영합니다.",
    "modal_subtitle": "나의 채널을 가장 쉽고, 안전하게 보호하는 방법, Global Pass!👇\nGlobal Pass 인증을 완료하시면 Global Pass 인증 카드를 발급해 드리며, 채널 소유권 인증, 수익화, 저작권 보호 등 다양한 혜택을 받으실 수 있습니다.🎉",
    "modal_subtitle_tips": "Global PASS 인증 전, 체험판을 통해 adobaro를 먼저 경험해보실 수 있습니다.",
    "modal_plus_title": "Global Plus PASS 인증이 필요합니다.",
    "modal_plus_subtitle": "Global Plus PASS를 인증하시면, 아래의 기능을 사용하실 수 있습니다.",
    "modal_item1": "플랫폼 활동으로 인한 수익발생의 시작!",
    "modal_item2": "더 많은 플랫폼 개통",
    "modal_item3": "데이터 맞춤형 분석시스템 제공",
    "modal_item4": "그 밖의 다양한 기능이 제공됩니다.",
    "modal_btn_next": "다음에 하겠습니다.",
    "modal_btn_go": "PASS 인증하러 가기",
    "modal_btn_trial": "체험하러 가기",
    "modal_certify_title": "플랫폼 수익화의 자격요건 들어보셨나요?",
    "modal_certify_column_1": "자격요건",
    "modal_certify_column_2": "Y사",
    "modal_certify_column_3": "adobaro",
    "modal_certify_require_1": "구독자",
    "modal_certify_require_2": "시청시간",
    "modal_certify_require_3": "조회수",
    "modal_certify_company_1": "1,000명",
    "modal_certify_company_2": "4,000시간",
    "modal_certify_company_3": "1,000만",
    "modal_certify_ro_1": "0",
    "modal_certify_ro_2": "0",
    "modal_certify_ro_3": "0",
    "modal_certify_explication_1": "Y사 평균 수익화까지의 소요시간 대략 7개월?\n아도바로는 Global PASS 인증과 동시에 수익화가 개통됩니다.",
    "modal_certify_explication_2": "그 뿐 일까요?\n개통 가능 플랫폼 확장\n업로드 주 2회로 업그레이드\n파일 스토리지 용량 증가 등등등!",
    "modal_certify_explication_3": "Global PASS 인증 혜택을 지금 바로 시작해보세요",
    "modal_certify_btn": "PASS 인증 후 수익 시작!",
    "auth_center": "Global PASS",
    "auth_center_subtitle": "인증 정보 관리",
    "auth_global": "Global PASS",
    "auth_creator": "Global Plus PASS",
    "auth_history": "사용 이력",
    "auth_global_subtitle": "플랫폼 수익 개통, 채널 안전성 향상",
    "auth_creator_subtitle": "조건 달성 후 개통 가능",
    "auth_history_subtitle": "플랫폼별 PASS 사용 히스토리",
    "auth_global_title": "Global PASS",
    "auth_creator_title": "Global Plus PASS",
    "auth_history_title": "PASS 사용내역",
    "creator_auth_tip": "Global PASS를 먼저 진행해 주세요.",
    "pass_title": "인증완료",
    "passport_title": "여권 정보",
    "passport_tip": "플랫폼별 정산을 받기 위해서는 Global PASS를 인증해야 합니다. 아래와 같이 인증에 필요한 서류를 제출해 주세요.",
    "platform_title": "플랫폼 정보",
    "platform_name": "플랫폼 명",
    "platform_url": "플랫폼 링크",
    "passport_dashboard_title": "서명된 여권 스캔본",
    "passport_dashboard_tip": "서명된 상태인지 확인 후 파일을 업로드해 주세요.",
    "passport_handhold_title": "서명된 여권과 본인 셀카",
    "passport_handhold_tip": "서명된 여권이 잘 보이도록 촬영해야 합니다.",
    "platform_dashboard_title": "스튜디오 대쉬보드 스크린샷",
    "platform_handhold_title": "대쉬보드와 본인 인증 셀카",
    "btn_submit_picture": "인증을 위한 정보 제공에 동의합니다.",
    "protocol_tip": "{0} 를 충분히 숙지했고, 이에 동의합니다.",
    "protocol_platform": "아도바로 위임 동의서",
    "passport_question": "왜 여권이 필요한가요?",
    "passport_answer": "해외 여행을 가실 때 여권을 가지고 가듯이 해외 플랫폼 운영 시에도 본인 확인을 위한 여권이 사용됩니다.\n중국 플랫폼의 가입을 위해서는 실명인증이 필요하며, 아도바로의 Global PASS 인증 시스템을 통해 귀하의 개인 정보가 보호됩니다.\nGlobal PASS 인증을 통하면 별도의 심사 없이 각각의 플랫폼의 업로드 권한이 주어지며, 플랫폼에서의 활동으로 인한 수익도 발생됩니다.",
    "passport_income_comparison": "Y사의 수익화 자격 요건과 아도바로 비교",
    "passport_pass_success": "아도바로 Global PASS 인증 시 수익화 자격 획득",
    "pass_no": "인증 번호",
    "pass_duration": "인증 기간",
    "pass_explain": "{0}를 위해 제출한 여권 정보는 개인정보 보호를 위해 표시되지 않습니다.",
    "pass_in_review": "{0}이 신청되었습니다. 심사일은 업무일 기준 3~5일 정도 소요되며, 심사 기간 중 본인 인증을 위한 메일이 발송됩니다.",
    "pass_in_fail": "{0} 심사가 승인되지 않았습니다. 미승인 사유: {1}",
    "pass_expire_warning": "{0}이 {1}에 만료됩니다. 원활한 이용을 위해 재신청을 진행해 주세요.",
    "pass_expire_already": "{0}가 만료되었습니다. 원활한 이용을 위해 재신청을 진행해 주세요.",
    "pass_submit_success_tip": "{0}가 안전하게 제출되었습니다. 심사일은 업무일 기준 3~5일 정도 소요되며, 심사를 기다리시는 동안에도 플랫폼 개통 신청은 가능합니다.",
    "pass_submit_fail_tip": "{0} 제출이 실패했습니다.",
    "auth_attention": "인증 시, 주의 사항",
    "global_attention_question_1": "1. 왜 여권이 필요한가요?",
    "global_attention_answer_1": "여권이 필요한 것의 대답",
    "global_attention_question_2": "2. 두번째 질문",
    "global_attention_answer_2": "두번쨰 질문에 대한 답",
    "global_attention_question_3": "3. 세번째 질문",
    "global_attention_answer_3": "세번째 질문에 대한 답",
    "global_attention_question_4": "4. 네번째 질문",
    "global_attention_answer_4": "네번째 질문에 대한 답",
    "global_attention_pass_question_1": "1. 인증 후, Global 인증을 취소하려면 어떻게 해야 하나요?",
    "global_attention_pass_answer_1": "답변",
    "global_attention_pass_question_2": "2. 두번째 질문",
    "global_attention_pass_answer_2": "답변",
    "creator_attention_question_1": "1. 왜 플랫폼 대쉬보드가 필요한가요?",
    "creator_attention_answer_1": "답변",
    "creator_attention_question_2": "2. 두번째 질문",
    "creator_attention_answer_2": "두번쨰 질문에 대한 답",
    "creator_attention_question_3": "3. 세번째 질문",
    "creator_attention_answer_3": "세번째 질문에 대한 답",
    "creator_attention_question_4": "4. 네번째 질문",
    "creator_attention_answer_4": "네번째 질문에 대한 답",
    "creator_attention_pass_question_1": "1. 인증 후, Global Plus PASS인증을 취소하려면 어떻게 해야 하나요?",
    "creator_attention_pass_answer_1": "답변",
    "creator_attention_pass_question_2": "2. 질문",
    "creator_attention_pass_answer_2": "답변",
    "attention_know_more": "더 알아보기",
    "cancel_auth": "PASS 인증 취소",
    "cancel_auth_title": "PASS 인증을 정말 취소하시겠습니까?",
    "cancel_auth_subtitle": "PASS 인증을 취소하게 되면, 모든 운영 채널의 수익이 정지되며, 개통된 채널은 모두 탈퇴처리가 됩니다. 그대로 진행하시겠습니까?",
    "cancel_auth_success": "PASS 인증 취소 성공",
    "cancel_auth_fail": "PASS 인증 취소 실패",
    "attention_approve": "위 답변에 만족하시나요?",
    "history_country": "국가",
    "history_platform": "플랫폼",
    "history_auth_type": "PASS 인증",
    "history_use_todo": "용도",
    "history_timestamp": "시간",
    "use_type_handling": "불펌처리",
    "use_type_open_income": "플랫폼 수익",
    "use_type_open_platform": "채널 개설",
    "upload_global_pass": "Global Pass 인증하기",
    "upload_cancel": "취소",
    "upload_confirm": "확인",
    "global_pass_failed": "여권 인증 실패",
    "global_pass_failed_des": "플랫폼 피드백에 따라 인증에 실패했습니다. 실패 사유: {0}. 정보를 검토한 후 다시 업로드해 주세요.",
    "upload_btn": "정보 업로드",
    "account_create_success": "Global Pass 사용 동의",
    "allow_global_pass": "플랫폼 채널 개설 및 인증 절차를 위해 Global Pass 정보 사용에 동의해 주세요.",
    "allow_and_accredit": "동의",
    "upload_global_pass_subtitle": "Global Pass 인증을 위해 본인 명의 여권을 제출해 주세요. ",
    "back_trial_version": "닫기",
    "upload_pass_more": "귀하의 여권 정보가 이미 제출되었습니다.",
    "upload_pass_tips": "*제출된 자료는 Global Pass 인증 목적으로만 사용되며, 모든 개인 정보는 철저히 보호됩니다."
  },
  "home": {
    "read_more": "더보기",
    "all_platform_statistic": "내 채널 현황",
    "statistic_date": "통계 데이터 {0}",
    "statistic_platform": "보유 채널수",
    "all_platform_fans": "구독자수(전체)",
    "all_platform_plays": "조회수(전체)",
    "latest_content": "업데이트",
    "latest_popular": "내 채널",
    "read_details": "상세 내역",
    "earnings_overview_title": "수익 현황",
    "earnings_money": "정산 가능 금액",
    "earnings_point": "포인트",
    "platform_news": "플랫폼 뉴스",
    "news_content": "adobaro에 오신걸 환영합니다!!\n플랫폼 뉴스에선 아도바로에 관한 이야기와 신규 기능들을 소개해드립니다, 앞으로 많은 관심 부탁드립니다.",
    "news_help_btn": "도움 센터 살펴보기",
    "unlocked_task_card": "임무 카드 보러 가기",
    "unlocked_to_content": "콘텐츠 관리",
    "unlocked_to_upload": "콘텐츠 업로드",
    "unlocked_to_open": "플랫폼 개통"
  },
  "upload": {
    "file_btn_status_removed": "업로드",
    "file_btn_status_uploading": "업로드 중",
    "file_btn_status_done": "파일 변경",
    "file_btn_status_error": "재업로드",
    "file_dragger_subtitle": "업로드 또는 파일을 여기로 끌어 놓으세요.",
    "file_dragger_tip": "지원파일: {0}",
    "file_status_uploading": "업로드 중:",
    "file_status_done": "완료",
    "file_status_error": "업로드 실패",
    "file_type_error": "지원하지 않는 파일 형식입니다.",
    "file_limit_size": "파일 크기는 {0} 를 넘어선 안 됩니다.",
    "file_limit_size_tip": "(최대 {0})",
    "file_limit_min_size": "(*{0} 이하)",
    "file_change_tip": "파일 변경",
    "file_upload_image_tip": "썸네일 업로드",
    "file_change_image_tip": "썸네일 변경",
    "file_name_already_exist": "파일저장공간 내 중복된 파일명이 있습니다. 파일명을 변경 후 다시 업로드 해 주세요.",
    "file_size_exceeds_capacity": "스토리지 용량이 가득 찼습니다.잔여 용량을 확인해 주세요.",
    "example_pic_tip": "예시",
    "guide_tips": "{0} 만 준비하시면 됩니다.",
    "guide_tip_content": "오리지널 영상, 썸네일, 제목, 그리고 소개",
    "guide_subtitle": "(✿◡‿◡) 콘텐츠를 업로드 하시면, 각 플랫폼에 최적화된 제목과 썸네일이 자동 생성되어 업로드됩니다. ✌️",
    "guide_upload_btn": "확인",
    "page_title": "업로드",
    "page_subtitle": "(✿◡‿◡) 콘텐츠를 업로드 하시면, 각 플랫폼에 최적화된 제목과 썸네일이 자동 생성되어 업로드됩니다. ✌️",
    "upload_file_holder": "파일을 업로드해 주세요.",
    "upload_file_complete_tip": "파일 업로드가 완료된 후 진행해 주세요.",
    "select_platform": "채널 선택",
    "select_platform_holder": "업로드할 채널을 선택해 주세요.",
    "open_more_platform": "채널 개설하기",
    "basic_info": "콘텐츠 정보",
    "basic_info_tip": "adoba.AI가 각각의 플랫폼에 최적화된 콘텐츠로 자동 변환되어 업로드됩니다.",
    "basic_content_title": "콘텐츠 제목",
    "basic_content_title_holder": "콘텐츠 제목을 입력해 주세요. 입력하신 내용은 플랫폼에 맞게 자동으로 최적화된 중국어로 번역되어 업로드됩니다.",
    "basic_content_cover": "썸네일",
    "basic_content_cover_holder": "썸네일을 업로드해 주세요.",
    "basic_content_cover_upload": "지원파일: {0}",
    "basic_content_cover_upload_tip": "⭐AI썸네일 기능을 통해 각 플랫폼에 최적화된 썸네일이 자동으로 생성되어 업로드됩니다.\r\n🚨AI썸네일 기능을 사용하기 위해서는 반드시 썸네일 이미지에 글자가 포함되어 있지 않는 클린본을 업로드해 주세요.",
    "basic_content_cover_change_tip": "썸네일 변경",
    "basic_content_intro": "콘텐츠 소개",
    "basic_content_intro_holder": "콘텐츠 소개 내용을 입력해 주세요. 입력하신 내용은 플랫폼에 맞게 자동으로 최적화된 중국어로 번역되어 업로드됩니다.",
    "basic_content_subtitle": "자막 선택",
    "basic_content_subtitle_holder": "자막을 선택해 주세요.",
    "basic_content_timer_release": "예약 업로드",
    "basic_content_timer_release_tip": "{0} 시간 이후부터 설정이 가능합니다.",
    "btn_release": "AI 스마트 업로드",
    "btn_draft": "임시 저장",
    "draft_title": "임시 저장된 콘텐츠",
    "draft_subtitle": "업로드가 완료되지 않은 임시 저장 콘텐츠가 있습니다.\n계속 진행하시겠습니까?",
    "draft_read_image_error": "이미지 로딩에 실패했습니다. 다시 업로드해 주세요.",
    "draft_save_tips": "업로드 완료 후 임시 저장이 가능합니다.",
    "draft_save_success": "임시 저장 성공",
    "release_content_success": "업로드 신청이 완료되었습니다.🎉\r\n업로드 진행결과는 알림 및 이메일을 통해 안내드립니다.\r\n💡불법, 성인물 등 각 플랫폼 규정에 따라 적합성 심사가 진행되며 그 결과에 따라 업로드 요청이 거절될 수 있습니다.",
    "release_content_fail": "업로드에 실패했습니다.",
    "release_count": "{0}회 남음",
    "platform_frozen": "잠김",
    "platform_unbound": "인증 필요",
    "trial_count_out": "이번 주 체험판 업로드 사용이 완료되어, 업로드가 불가합니다.",
    "publish_count_out": "가능한 업로드 횟수를 모두 사용 완료하여, 업로드가 불가합니다.",
    "release_upload_tips": "⭐AI 스마트 업로드 기능을 통해 콘텐츠와 각 플랫폼 주요 활성화 시간을 분석하여, 가장 최적의 시간에 맞춰 자동으로 업로드됩니다.\n🚨본인 확인 절차가 필요한 경우, 인증번호 확인절차가 필요할 수 있습니다.",
    "release_time_tips": "⚠️업로드 횟수는 현지 시간 기준 {0} (UTC 00:00)에 갱신됩니다."
  },
  "material": {
    "page_title": "파일 드라이브",
    "page_subtitle": "업로드된 콘텐츠를 손쉽게 관리하고, 다른 플랫폼으로 한 번에 배포할 수 있습니다.",
    "sider_my_file": "내 문서",
    "sider_enlarge_capacity": "클라우드 용량",
    "menu_video": "영상",
    "menu_images": "사진",
    "menu_sounds": "효과음",
    "menu_subtitles": "자막",
    "menu_fonts": "폰트",
    "menu_bgm": "BGM",
    "menu_backup_space": "백업 클라우드",
    "alert_capacity": "용량을 확장하여 아도바로 기능을 최대한 활용해 보세요.",
    "alert_upgrade": "용량 확장",
    "all_backups": "선택 백업",
    "all_delete": "선택 삭제",
    "all_download": "선택 다운로드",
    "confirm_title_delete": "정말 삭제하시겠습니까?",
    "confirm_ok": "삭제",
    "confirm_cancel": "취소",
    "selected_items": "{0}개의 파일이 선택됨",
    "backup_btn": "백업",
    "delete_btn": "삭제",
    "download_btn": "다운로드",
    "pagination_total": "총 {0} 개",
    "video_title": "전체 영상",
    "video_delete_confirm": "{0}개의 영상을 삭제하시겠습니까?",
    "video_deleted_description": "삭제된 영상은 신규 플랫폼에 2차 배포가 불가합니다.",
    "video_name": "파일명",
    "video_size": "사이즈",
    "video_published_platform": "배포 플랫폼",
    "video_opened_platform": "개통 플랫폼",
    "image_title": "전체 사진",
    "subtitles_title": "전체 자막",
    "subtitles_delete_confirm": "정말 {0}개의 파일을 삭제하시겠습니까?",
    "subtitles_deleted_description": "삭제된 파일은 신규 플랫폼에 2차 배포가 불가합니다.",
    "subtitles_name": "파일명",
    "subtitles_size": "사이즈",
    "subtitles_lang": "언어",
    "modal_title": "이름 변경",
    "modal_name_label": "이름",
    "modal_name_holder": "이름을 입력해 주세요.",
    "modal_reference_batch": "업로드 심사중인 콘텐츠는 삭제할 수 없어요",
    "modal_reference_single": "업로드 심사중인 콘텐츠에요, 삭제할 수 없어요"
  },
  "content": {
    "page_title": "콘텐츠 관리",
    "page_subtitle": "아도바로에서 여러 채널에 업로드한 콘텐츠를 한 곳에서 편리하게 관리하세요. 각 채널별 업로드 진행 상황과 데이터를 쉽게 확인할 수 있습니다.✌️",
    "radio_all": "전체",
    "radio_play": "높은 조회수 순",
    "radio_like": "높은 좋아요 순",
    "page_details_title": "콘텐츠 상세보기",
    "details_under_review": "AI 콘텐츠 자동 검수가 진행중이에요, 잠시만 기다려주세요",
    "details_in_failure": "AI 검수 결과, 아래 항목 개선이 필요해요. \n수정을 완료하신 다음,  다시 업로드 해 주세요",
    "base_info": "기본 정보",
    "base_video": "영상",
    "base_cover": "썸네일",
    "base_title": "제목",
    "base_subtitle": "자막",
    "base_upload_date": "업로드시간",
    "empty_cover": "썸네일이 없네요",
    "mcn_upload": "MCN업로드",
    "distributed_platform": "업로드 현황",
    "distributed_name": "플랫폼",
    "distributed_state": "상태",
    "distributed_subtitle": "자막",
    "distributed_plays": "조회 수",
    "distributed_likes": "좋아요 수",
    "distributed_upload_date": "업로드 시간",
    "distributed_action": "관리",
    "status_playing": "업로드 성공",
    "status_opening": "검토중",
    "status_failure": "업로드 실패",
    "status_unpublished": "업로드 가능",
    "status_not_opened": "채널 없음",
    "status_frozen": "잠김",
    "status_deleting": "삭제 중",
    "status_deleted": "삭제 완료",
    "action_operate": "링크",
    "action_ask": "문의하기",
    "action_publish": "업로드",
    "action_open": "채널 개설",
    "action_check": "보기",
    "action_analysis": "분석",
    "action_edit": "수정",
    "action_delete": "삭제",
    "action_unfreeze": "잠김 해제",
    "delete_video_title": "삭제 신청한 영상은 복구가 어려워요. 정말 삭제하시곘어요?",
    "delete_video_success": "삭제 신청이 접수되었어요!",
    "delete_video_error": "삭제 신청에 실패했어요.",
    "republish_platform_success": "성공적으로 업로드 되었습니다.",
    "republish_platform_error": "업로드에 실패했습니다.",
    "republish_platform_error_insufficient": "동영상 업로드 실패. 업로드 가능횟수 초과",
    "republish_content_can": "{0} 채널에 추가로 업로드 하시겠습니까? \n잔여 횟수: {1}회",
    "republish_content_cannot": "{0}플랫폼에 게시 가능한 영상의 남은 횟수가 {1}회이므로 영상을 제출할 수 없습니다.",
    "republish_content_certify_warning": "인증 완료후에 업로드 가능한 플랫폼입니다. 인증을 먼저 완료해주세요.",
    "video_url_error": "업로드 링크를 찾지 못 했습니다.",
    "failure_reason": "실패 이유",
    "distributed_state_intro": "업로드 성공 : 콘텐츠 심사 업로드가 완료된 상태입니다.\n검토중 : 각 플랫폼별 콘텐츠 검토 및 업로드를 위한 필요 절차를 진행하는 단계입니다.\n업로드 가능 : 채널이 개설되어 있으며 해당 콘텐츠 업로드 신청이 가능한 상태입니다.\n채널 없음 : 채널이 아직 개설되지 않은 상태입니다.",
    "no_data": "원본파일이 삭제되었습니다."
  },
  "translation": {
    "page_subtitle": "이 곳에서 자막을 번역할 수 있습니다.",
    "srt_translation": "SRT변환",
    "ai_translation": "AI 번역",
    "manual_translation": "전문 번역",
    "translation_download": "요청 내역",
    "language_select": "언어를 선택해주세요.",
    "language_zh": "중국어 (간체)",
    "language_ko": "한국어",
    "language_en": "영어",
    "srt_translation_subtitle": "SRT 파일을 가지고 계신가요? 중국어(간체) SRT 파일로 변환해드려요.",
    "srt_step_language": "번역할 언어를 선택해주세요.",
    "srt_step_language_tip": "업로드한 파일을 {0}로 번역 완료될 예정입니다",
    "srt_step_lang_model": "언어 번역처리 모델 선택",
    "srt_lang_model_holder": "언어 번역처리 모델을 선택해주세요.",
    "srt_lang_model_tip_tiny": "매우 빠르지만, 정확도가 높지 않습니다.",
    "srt_lang_model_tip_base": "비교적 빠르고, 정확도가 높습니다.",
    "srt_lang_model_tip_small": "일반적이며, 정확도가 더 높습니다.",
    "srt_lang_model_tip_medium": "느리지만, 정확도가 좀 더 높습니다.",
    "srt_lang_model_tip_large": "매우 느리지만, 정확도는 가장 높습니다.",
    "srt_select_translation_engine": "번역 엔진을 선택하십시오.",
    "srt_select_chatgpt_model": "GPT모델을 선택하세요.",
    "srt_step_upload_file": "SRT 파일을 업로드해주세요",
    "srt_step_tip_manual": "완벽한 수준의 번역이 필요하신가요? {0}에서 도와드려요.",
    "srt_translation_tip1": "무료로 제공되는 서비스입니다.",
    "srt_translation_tip2": "보통 1시간 이내에 완료됩니다. 요청 내역을 확인해 주세요.",
    "srt_translation_tip3": "번역 엔진을 기반으로 작동하므로 번역의 정확성이 미흡할 수 있습니다.",
    "ai_translation_subtitle": "SRT 파일 없이 영상만 가지고 계신가요? 영상만 있어도 중국어 (간체) SRT 파일을 만들어 드려요.",
    "ai_step_title_1": "영상 언어를 선택해주세요.",
    "ai_step_tip_1": "선택하신 영상 언어는 {0} 으로 번역될 예정입니다.",
    "ai_step_title_2": "영상 업로드",
    "ai_file_type": "업로드 형식",
    "ai_file_type_video": "직접 업로드",
    "ai_file_type_youtube": "YouTube 링크",
    "youtube_url": "YouTube 링크를 입력해주세요.",
    "youtube_url_placeholder": "예시: https://www.youtube.com/adobaro",
    "youtube_url_error": "YouTube 링크 형식이 틀립니다.",
    "ai_step_tip_2": "완벽한 수준의 번역이 필요하신가요? {0}에서 도와드려요.",
    "ai_translation_tip1": "무료로 제공되는 서비스입니다.",
    "ai_translation_tip2": "보통 24시간 이내에 완료됩니다. 요청 내역을 확인해 주세요.",
    "ai_translation_tip3": "SRT 변환 엔진, 번역 엔진을 기반으로 작동하므로 번역의 정확성이 미흡할 수 있습니다.",
    "manual_translation_subtitle": "보다 나은 퀄리티의 번역을 원하시나요? 전문가를 통한 번역을 요청하세요.",
    "manual_translation_tip1": "유료로 제공되는 서비스입니다.\n한국어, 영어에서 중국어(간체) 번역 시 분당 5000원\n‘영상 소개’ 번역 선택시 2000원 추가\n‘번역없이 자막만’ 선택시 분당 2000원",
    "manual_translation_tip2": "보통 48시간 이내에 SRT형태로 제공됩니다. 요청 내역을 확인해 주세요.",
    "manual_translation_tip3": "업로드 전에 해당 영상을 사용/재배포 하는 것이 저작권의 소유주인지 반드시 확인해주세요.\n저작권자의 동의가 필요한 경우, 적절한 동의를 얻은 후 번역을 요청해주세요.",
    "manual_translation_tip4": "해당 영상이 저작권법을 위반하거나 명예훼손에 해당하지 않는지 반드시 확인해주세요.\n성적으로 노골적이거나 학대, 혹은 불법적인 내용을 포함하고 있지 않은지 반드시 확인해주세요.",
    "video_duration": "영상 길이",
    "video_title": "영상 제목",
    "video_title_tip": "영상 제목을 입력해주세요.",
    "video_intro": "영상 소개",
    "video_intro_tip": "영상 소개를 입력해주세요.",
    "video_intro_check": "영상을 소개하는 설명글도 번역하실 경우 선택해주세요.",
    "subtitle_file": "자막 파일",
    "subtitle_file_tip": "이미 자막 파일을 가지고 계시면, 할인 혜택을 받으실 수 있습니다.",
    "manual_type": "번역 없이\n자막만",
    "manual_type_tip": "영상 언어를 번역하지 않고, 영상 언어의 SRT 파일을 원하실 때 선택해주세요.",
    "manual_language_tip": "{0} 에서 {1} 로 번역이 될 예정입니다.",
    "manual_unit_count": "{0}개",
    "manual_fee": "총 요청 금액",
    "manual_fee_unit": "원",
    "protocol_tip": "서비스 이용 내용을 숙지하였고,\n이에 동의합니다.",
    "protocol_check_tip": "먼저 서비스 이용에 동의해주셔야 합니다.",
    "btn_manual_apply": "전문 번역 요청하기",
    "payment_process": "결제가 시작됩니다.",
    "translation_download_subtitle": "요청하신 번역은 여기에서 확인하실 수 있습니다.",
    "file_name": "파일 명",
    "translation_category": "구분",
    "service_category": "요청 유형",
    "translation_status": "진행 상태",
    "target_language": "대상언어",
    "apply_date": "요청일",
    "translation_action": "요청 자막",
    "action_download": "다운로드",
    "action_fail_reason": "실패 사유",
    "select_apply_date": "요청일을 선택해주세요.",
    "start_date": "시작일",
    "end_date": "마지막날",
    "option_all": "전체",
    "service_cate_translate": "번역 신청",
    "service_cate_srt": "자막만 신청",
    "translation_status_waiting": "대기",
    "translation_status_translating": "진행중",
    "translation_status_success": "완료",
    "translation_status_fail": "실패",
    "total_count": "총 {0} 개의 데이터",
    "apply_success": "요청 성공",
    "apply_fail": "요청 실패",
    "btn_continue_translate": "요청 계속하기",
    "btn_retry_translate": "재요청",
    "btn_translate": "번역 요청",
    "success_tip": "현재 요청 중인 {0} 의 진행 상태를 확인 하실 수 있고, 번역이 완료된 후에 자막 다운로드가 가능합니다.",
    "success_tip_list": "요청 리스트",
    "fail_tip": "요청이 실패되었습니다, {0} 에 연락주시면 해결해드리겠습니다.",
    "fail_tip_customer": "고객 센터",
    "fail_already_exist": "이미 중복된 요청이 존재합니다."
  },
  "platform": {
    "guide_title": "채널 분석",
    "guide_subtitle": "본인의 채널ID를 입력하시면, AI분석을 통해 최적의 플랫폼을 추천해 드립니다.",
    "guide_platform_holder": "플랫폼을 선택해 주세요.",
    "guide_platform_url_holder": "채널 ID를 입력해 주세요.",
    "guide_platform_url_error": "채널 ID를 다시 확인해 주세요.",
    "guide_platform_already": "이미 등록된 플랫폼 채널입니다.",
    "guide_platform_confirm": "채널 정보를 제출하면, 당분간 수정이 불가능합니다.\n입력된 정보가 맞다면 \"확인\" 을 눌러주세요",
    "guide_platform_tip": "채널ID는 어디에서 확인할 수 있나요?",
    "guide_btn_recommend": "플랫폼 추천",
    "guide_platform_error": "플랫폼 추천 실패",
    "guide_skip_title": "나중에 하기",
    "guide_skip_subtitle": "나중에 하시려는 이유가 무엇인지 알려 주세요.",
    "guide_skip_holder": "이유를 선택해 주세요.",
    "guide_skip_reason_1": "운영 채널이 없습니다.",
    "guide_skip_reason_2": "이미 개통한 채널이 있습니다.",
    "guide_skip_reason_3": "원하는 국가 플랫폼이 없습니다.",
    "guide_skip_reason_4": "기타 이유",
    "guide_skip_btn": "메인으로 가기",
    "guide_analysis_title": "분석중",
    "guide_analysis_subtitle": "채널 분석을 통해 최적은 플랫폼을 추천해 드립니다.",
    "guide_analysis_fail_title": "현재 이용자가 많습니다.",
    "guide_analysis_fail_subtitle": "분석이 완료된 후 이메일로 알려 드릴께요.",
    "guide_analysis_fail_btn_next": "다음에 할께요.",
    "guide_analysis_fail_btn_confirm": "알려 주세요.",
    "guide_analysis_done_title": "채널 분석 완료",
    "guide_analysis_done_subtitle": "앞으로 업로드하는 콘텐츠는 플랫폼에 자동으로 최적화됩니다. ",
    "guide_analysis_platform_about": "플랫폼 이해돕기",
    "guide_analysis_btn_open": "개설 신청",
    "guide_analysis_btn_already": "기존 채널 연결하기",
    "guide_analysis_btn_next": "다음에 할게요",
    "guide_recommend_fail": "플랫폼 개통 실패",
    "guide_bind_title": "채널 연동",
    "guide_bind_subtitle": "이미 개통한 채널이 있다면, 아도바로에 연동해 보세요~\n모든 플랫폼을 한 번에 관리하실 수 있습니다.",
    "guide_bind_tip": "왜 비밀번호를 입력해야 하나요?",
    "guide_bind_btn_confirm": "채널 연동하기",
    "guide_bind_btn_next": "다음에 연동할게요.",
    "guide_bind_success": "플랫폼 개통이 신청되었습니다. 승인 여부는 이메일을 통해 안내되오니, 메일을 확인해 주세요.",
    "guide_open_phone_title": "번호 등록",
    "guide_open_phone_subtitle": "본인 명의의 휴대폰 번호를 입력해 주세요.",
    "guide_enter_phone": "휴대폰 번호를 입력해 주세요",
    "guide_open_btn_verify": "다음",
    "guide_open_send_sms_error": "인증 발송 실패",
    "guide_open_verify_title": "인증번호 발송",
    "guide_open_verify_subtitle": "인증번호 발송 버튼을 클릭하시면 인증번호가 발송됩니다. 시스템 상태에 따라 인증번호 수신이 지연될 수 있으니, 잠시 기다려 주십시오.수신문자예시:",
    "guide_open_btn_verifying": "인증하기",
    "guide_open_verify_error": "인증에 실패했습니다.",
    "manage_title": "채널 관리",
    "manage_subtitle": "(✿◡‿◡)쉽고 안전한 채널 개설, 바로 아도바로에서 시작하세요. 채널개설과 인증에 필요한 모든 복잡한 과정들을 아도바로에서 정확하고 안전하게 진행해 드립니다. ✌️' ֊' ",
    "applied_platform": "채널 개설 현황",
    "btn_refresh": "데이터 갱신",
    "btn_refresh_tip": "클릭 시 채널개설 현황이 갱신됩니다.",
    "pending_tip_content": "입력하신 휴대폰 번호로 플랫폼 개통이 진행될 예정입니다. 새로고침 버튼을 눌러 하단에 \"인증번호 발송\" 버튼이 생성되면, 클릭 후 인증을 진행해 주세요.",
    "btn_all": "전체",
    "btn_play_most": "높은 조회수 순",
    "btn_like_most": "높은 좋아요 순",
    "platform_empty": "채널 개설을 시작해 보세요.",
    "platform_frozen_tip": "보기 또는 잠금해제 클릭",
    "platform_view_btn": "플랫폼 보기",
    "status_done": "개설/인증 완료",
    "status_opening": "개설중",
    "status_verifying": "인증 중입니다. 잠시만 기다려 주세요.",
    "status_verify_failed": "인증 실패",
    "status_failed": "개통 실패",
    "status_unbind": "개설완료, 인증중",
    "status_binding": "개설승인 완료, 인증 진행중",
    "status_bound_fail": "인증오류",
    "btn_status_done": "업로드",
    "btn_status_failed": "자세히 보기",
    "status_opening_intro": "개설중 : 채널 개설에 필요한 절차를 진행하는 단계입니다.",
    "status_unbind_intro_1": "개설완료, 인증중 : 채널 개설 절차가 완료되었으며, 신분인증, 소유권인증, 채널인증, 적합성 검사 등 각 플랫폼 규정에 따라 필수 인증 절차를 진행하는 단계입니다. ",
    "status_unbind_intro_2": "인증을 완료하기 위해 첫 업로드 진행이 필요합니다.",
    "status_unbind_intro_3": "*플랫폼에 따라 총 5-30일 정도 소요될 수 있습니다.",
    "status_done_intro": "개설/인증 완료: 채널 개설 및 인증 절차가 모두 완료된 단계입니다.",
    "un_apply_platform": "신청 가능 플랫폼",
    "open_count": "{0} 명의 유저가 이미 개통했습니다.",
    "lastest_open": "유저 {0} {1} 개통",
    "btn_open": "개설 신청",
    "btn_reapply": "재신청",
    "open_platform_title": "채널 개설",
    "btn_contact_us": "문의하기",
    "platform_recommend": "추천",
    "platform_disable": "점검중",
    "platform_disable_title": "알림",
    "platform_disable_content": "{0} 플랫폼 시스템 점검으로 인해 채널 개설 서비스가 일시중단 되었습니다. 문의사항이 있으시면 고객센터로 문의해 주시기 바랍니다.🧏‍♀️",
    "platform_conditions_required": "🚨 신청 불가: 플랫폼 개설 신청 조건을 충족하지 못했습니다. 신청 조건을 확인 후 다시 시도해 주세요.",
    "modal_bound_fail_title": "인증에 실패했습니다. 👀",
    "modal_bound_fail_content": "아래 내용을 확인해 주세요\n\n⚠️원인: {0}",
    "modal_bound_fail_btn": "인증 완료하기",
    "open_platform_subtitle": "채널개설과 인증에 필요한 모든 복잡한 과정들을 아도바로에서 정확하고 안전하게 진행해 드립니다. ",
    "details_title": "플랫폼 요약",
    "details_info_title": "기본 정보",
    "details_info_avatar": "프로필 사진",
    "details_info_intro": "채널 소개",
    "details_info_intro_empty": "채널 소개가 없습니다.",
    "details_info_intro_holder": "채널 소개를 입력해 주세요.",
    "details_info_date": "채널 가입일",
    "details_info_date_empty": "데이터가 없습니다.",
    "details_info_date_holder": "채널 가입 시간을 선택해 주세요.",
    "details_info_classify": "채널 카테고리",
    "details_info_classify_empty": "데이터가 없습니다.",
    "details_info_classify_holder": "카테고리를 선택해 주세요.",
    "details_info_income": "플랫품 수익",
    "details_info_order": "광고",
    "details_info_btn_again": "재추천",
    "details_info_btn_open": "바로 개통하기",
    "details_info_btn_edit": "기본 정보 수정",
    "details_info_btn_submit": "저장 및 수정",
    "details_data_overview_title": "데이터 요약",
    "details_data_radio_yesterday": "어제",
    "details_data_radio_nearly7": "최근 7일",
    "details_data_radio_nearly30": "최근 30일",
    "details_data_fans": "누적 구독자 수",
    "details_data_likes": "누적 좋아요 수",
    "details_data_platform": "누적 플랫폼 수익",
    "details_data_published": "누적 배포 수",
    "details_data_plays": "누적 조회 수",
    "details_data_ad": "누적 광고 수익",
    "details_release_video_title": "배포된 콘텐츠",
    "trial_modal_title": "adobaro 체험판",
    "trial_modal_subtitle": "아도바로 체험판을 이용하시기 전에 꼭 읽어주세요!",
    "trial_modal_btn_agree": "체험하러 가기",
    "trial_protocol_title": "위 내용을 숙지하여 주시고, 즐거운 체험되세요.",
    "trial_msg_toggle_error": "체험판 진입에 실패하였습니다.",
    "trial_msg_certified_error": "이미 Global Pass 인증이 통과되어 체험판 사용이 불가합니다.",
    "trial_protocol_item_1": "체험버전을 이용하는 모든 분들의 콘텐츠가 공유되니, 업로드할 콘텐츠에 주의해주세요.",
    "trial_protocol_item_2": "체험버전에서 업로드된 콘텐츠는 다음날 모두 초기화 됩니다.",
    "trial_protocol_item_3": "체험버전에 업로드된 콘텐츠의 데이터는 체험버전을 위해서 만들어진 채널의 실제 데이터입니다.",
    "trial_protocol_item_4": "체험버전의 모든 기능은 정식버전의 기능과 동일합니다.",
    "trial_protocol_item_5": "원활한 체험을 위해 일부 기능은 지원되지 않습니다.",
    "trial_protocol_subtitle_2": "위 내용을 숙지하여 주시고, 즐거운 체험되세요.",
    "guide_exit_opening_platform": "현재 프로세스를 종료하면 입력한 정보가 손실되며, 다음 개통 신청 시 처음부터 다시 진행됩니다.",
    "guide_exit_cancel": "취소",
    "guide_exit_confirm": "확인",
    "guide_submit_success": "제출 성공",
    "guide_waiting_phone_code": "등록은 3~5분 후 완료될 예정이니 기다려 주세요.",
    "guide_know_btn": "알겠습니다.",
    "guide_change_account": "채널 변경",
    "guide_phone_code_verify": "인증번호 인증",
    "guide_pass_verify": "여권 검증",
    "guide_pass_code_verify": "인증번호 재인증",
    "guide_verify_fail": "인증 실패",
    "guide_click_verify": "검증 신청",
    "guide_check_fail_reason": "실패 원인 보기",
    "pending_tips": "플랫폼 오픈 중이니 새로고침 버튼을 누르시면, 바로 최신 정보를 받으실 수 있습니다. ",
    "doing_warning": "5~10초 뒤 다시 시도해 주세요.",
    "guide_open_submit_success": "휴대폰 번호가 입력되었습니다.",
    "guide_open_verify_success": "인증번호가 제출되었습니다.",
    "guide_click_send": "인증번호 전송",
    "guide_waiting_pass_code": "플랫폼 개통은 영업일 기준 1~2일이 소요됩니다. 조금만 기다려 주세요.",
    "guide_submit_phone_failed": "시스템 처리에 실패하였습니다. 잠시 후 다시 시도해주세요.",
    "have_opened": "개통완료",
    "guide_open_security": "플랫폼 자체 심사로 인해 개통에 시간이 필요한 플랫폼입니다(평균 2주). 다음 단계 준비가 완료되면 이메일과 알림이 발송됩니다.",
    "guide_get_code_error": "시스템 오류입니다. 잠시 후 다시 시도해 주세요.",
    "bilibili_tips_1": "비리비리 플랫폼은 \"중국판 유튜브\"로 널리 알려져 있으며, 특히 18-35세의 MZ세대 사이에서 큰 인기를 끌고 있습니다. 💖",
    "bilibili_tips_2": "이 플랫폼의 주 사용자층은 24세 이하로, 전체 사용자의 75%를 차지하고 있습니다. 또한, 비리비리는 월간 활성 이용자 수가 약 3억 15만 명에 달하고, 약 380만 명의 크리에이터가 활동 중입니다. 🏆",
    "bilibili_tips_3": "가로 및 세로 형식의 영상 모두를 지원하는 이 플랫폼은, 화면을 가로지르는 독특한 탄막(弹幕) 기능으로 젊은층에게 매력적인 디지털 콘텐츠 플랫폼으로 자리 잡고 있습니다.",
    "bilibili_tips_4": "- Global Pass 인증",
    "xiaohongshu_tips_1": "샤오홍슈 플랫폼은 \"중국판 인스타그램\"으로 알려져 있으며, 18-34세 여성들에게 인기가 높습니다.💖 월간 활성 이용자 수는 2억 6천만 명에 이르며, 약 2천만 명의 크리에이터가 활동 중입니다.🏆",
    "xiaohongshu_tips_2": "가로 및 세로 형식의 영상이 지원하며, 소셜네트워크와 전자상거래 기능을 결합한 형태로, 사용자들은 제품 리뷰, 여행지 소개 등을 텍스트와 동영상 형태로 공유합니다. 바둑판식 레이아웃이 특징인 샤오홍수는 젊은 사용자들에게 매력적인 콘텐츠를 제공하며, 이를 통해 강력한 바이럴 마케팅 효과를 발휘합니다.",
    "xiaohongshu_tips_3": "- Global Pass 인증",
    "kuaishou_tips_1": "\"중국판 틱톡\"으로 불리는 콰이쇼우는 주로 18세 이하의 10대들에게 인기가 높으며, 월간 활성 이용자 수가 6억 명에 달합니다.💖 200만 명 이상의 크리에이터가 활동하고 있으며, 주로 세로 형식의 영상을 지원합니다.🏆",
    "kuaishou_tips_2": "콰이쇼우는 서정적인 일상과 농촌 생활을 다루는 콘텐츠로 유명하며, 라이브커머스를 통한 매출이 높은 편입니다. 최근에는 젊은 사용자층이 늘어나고 있으며, 콘텐츠를 업로드 주기가 매우 중요한 플랫폼입니다.",
    "kuaishou_tips_3": "- Global Pass 인증",
    "iqiyi_tips_1": "아이치이는 \"중국판 넷플릭스\"로 불리며, 다양한 연령대의 사용자들이 이용하는 플랫폼입니다.💖 월간 활성 이용자 수는 5억 4천만 명에 달하며, 300만 명 이상의 크리에이터들이 활동하고 있습니다. 주로 가로 형식의 영상을 지원합니다.🏆",
    "iqiyi_tips_2": "아이치이는 드라마와 버라이어티쇼 등 다양한 글로벌 콘텐츠도 제작하고 있으며 비디오 서비스 플랫폼으로는 전세계적에서 넷플릭스 가장 많은 유료 구독자를 보유하고 있습니다.",
    "iqiyi_tips_3": "- Global Pass 인증",
    "haokan_tips_1": "하오칸 비디오는 \"중국판 네이버\"로 불리며, 주로 26-35세의 사용자층을 대상으로 합니다.💖 이 플랫폼의 월간 활성 이용자 수는 약 1억 1천만 명이며, 200만 명 이상의 크리에이터들이 활동 중입니다. 주로 가로 형식의 영상을 지원합니다.🏆",
    "haokan_tips_2": "하오칸 비디오는 중국 최대 검색엔진인 바이두의 대표적인 중편 영상 플랫폼으로, 정부의 영향을 많이 받아 비교적 보수적인 운영 정책을 가지고 있습니다.",
    "haokan_tips_3": "- Global Pass 인증",
    "haokan_tips_4": "- 중국 플랫폼 1개 이상 채널 개설 및 인증 완료",
    "haokan_tips_5": "- 유튜브, 틱톡 또는 중국 채널에 본인 얼굴이 출연하는 영상 1개 이상 보유 (*100만 구독자 이상 채널의 경우, 본인 얼굴 출연 영상 없이 특별심사 진행 가능)",
    "qqworld_tips_1": "\"중국판 카카오 스토리\"로 불리는 QQ World 플랫폼는 중국판 카카오톡(QQ)과 카카오스토리의 결합 형태로, 특히 1995년 이후 출생한 젊은 사용자들 사이에서 인기가 높습니다.💖 이 플랫폼의 월간 활성 이용자 수는 약 3억 5천만 명이며, 120만 명 이상의 크리에이터가 활동 중입니다. QQ는 가로 및 세로 형식의 영상을 지원하며, 조회수에 따른 수익은 제공하지 않습니다.🏆",
    "qqworld_tips_2": "주로 학생들을 포함한 젊은 연령대의 사용자들이 많아, 현대적이고 다양한 콘텐츠가 활발하게 공유됩니다.",
    "qqworld_tips_3": "- Global Pass 인증",
    "qqworld_tips_4": "- 중국 플랫폼 1개 이상 채널 개설 및 인증 완료\r",
    "xigua_tips_1": "시과 비디오 플랫폼은 \"중국판 유튜브\"로 알려져 있으며, 25-35세 사용자들이 45%를 차지하며, 남성과 여성의 비율이 비슷합니다. 💖월간 활성 이용자 수는 1억 8천만 명에 달하며, 약 320만 명의 크리에이터가 활동 중입니다. 주로 가로 형식의 영상을 지원합니다.🏆",
    "xigua_tips_2": "바이트댄스의 개인 맞춤형 콘텐츠 추천 알고리즘을 통해 넓은 사용자층과 다양한 콘텐츠 영역을 확보한 시과는 특히 브이로그와 '3농'(농업, 농촌, 농민) 분야의 콘텐츠에 대한 관심이 높습니다. 또한, 도우인 플랫폼과의 연계를 통해 시너지 효과를 발휘하며 빠른 성장을 기대할 수 있습니다.",
    "xigua_tips_3": "- Global Pass 인증",
    "xigua_tips_4": "- 중국 플랫폼 1개 이상 채널 개설 및 인증 완료\r",
    "bilibili_need_time": "예상 소요 기간 : 평균 1-2 영업일",
    "xiaohongshu_need_time": "예상 소요 기간 : 평균 2-3 영업일",
    "kuaishou_need_time": "예상 소요 기간 : 평균 2-3 영업일",
    "iqiyi_need_time": "예상 소요 기간 : 평균 2-3 영업일",
    "haokan_need_time": "예상 소요 기간 : 평균 10-15 영업일",
    "qqworld_need_time": "예상 소요 기간 : 평균 5-10 영업일",
    "xigua_need_time": "예상 소요 기간 : 평균 5-10 영업일",
    "guide_waiting_open_title": "채널 개설 신청이 완료되었습니다.🎊",
    "guide_waiting_open_subtitle": "채널개설과 인증에 필요한 모든 복잡한 과정들을 아도바로에서 대신하여 정확하고 안전하게 진행해 드립니다. 🔐",
    "guide_waiting_open_tip": "*기존 채널 운영 현황에 따라 개설 및 인증 과정과 소요 시간이 다를 수 있습니다.💖",
    "application_requirement": "⚠️신청조건",
    "guide_introduction": "플랫폼 소개"
  },
  "wallet": {
    "page_title": "My wallet",
    "page_subtitle": "point 와 플랫폼 수익을 보실 수 있습니다.",
    "platform_title": "플랫폼 수익",
    "a_point_title": "point",
    "overview_title": "현재 point",
    "overview_statistic_title": "총 point",
    "overview_btn_deposit": "충전",
    "overview_btn_withdrawal": "출금신청",
    "record_title": "point 기록",
    "record_date": "시간",
    "record_content": "내용",
    "record_amount": "수량",
    "record_enter": "입금 기록",
    "record_out": "출금 기록",
    "enter_date": "입금 시간",
    "enter_content": "내역",
    "enter_all": "전체",
    "out_date": "출금 시간",
    "out_content": "내역",
    "out_all": "전체",
    "coin_unit": "point",
    "scene_init_account": "가입 기념",
    "scene_open_platform": "플랫폼 개통",
    "scene_bind_platform": "플랫폼 연동",
    "scene_new_video": "콘텐츠 업로드",
    "scene_push_video": "플랫폼 업로드",
    "scene_withdrawal": "정산",
    "scene_recharge": "충전",
    "scene_platform_settlement": "플랫폼 수익",
    "scene_gift": "사전예약 혜택",
    "scene_transfer": "이체",
    "scene_passport": "Global Pass인증",
    "scene_invitation": "초대 리워드",
    "get_tips": "포인트가 [내 지갑]에 지급되었습니다.",
    "get_rule_title": "point 소개",
    "get_rule_content": "채널의 성장을 위해 사용 가능하며, 정산 신청을 통해 수익화도 가능한 아도바로 내 통용되는 코인입니다",
    "cost_use_btn": "사용하기",
    "cost_insufficient_title": "죄송합니다",
    "cont_insufficient_subtitle": "point가 부족하네요.",
    "cost_insufficient_tips": "필요 point {0} / 보유 point {1}"
  },
  "earning": {
    "modify_phone_verify_head": "신분 인증",
    "modify_phone_not_received": "인증번호를 받지 못하셨나요?",
    "situation_title": "플랫폼 수익 현황",
    "overview_title": "수익 개요",
    "detail_title": "정산 내역",
    "bank_identity_info": "정산 계좌/신분 정보",
    "account_info": "계정 정보",
    "monetary_unit_label": "금액 단위",
    "monetary_unit_cny": "위안",
    "monetary_unit_krw": "원",
    "monetary_unit_usd": "달러",
    "overview_balance": "수익 잔액",
    "overview_balance_tip": "미정산 수익 총 잔액",
    "overview_withdrawable": "정산 신청 가능 금액",
    "overview_withdrawable_tip": "수익 잔액 중 \n정산 신청이 가능한 금액",
    "overview_accumulate": "정산된 총 수익",
    "overview_accumulate_tip": "현재까지 정산된 총 금액",
    "withdrawable_apply_btn": "정산 신청하기",
    "withdrawable_apply_disable": "정산 가능 조건이 충족되지 않았거나, 정산 신청 가능일자가 아닙니다.",
    "withdrawable_detail_btn": "수익 상세",
    "withdrawable_mcn_tips": "정산은 소속 MCN에 문의하세요",
    "withdrawal_directions1": "⁕ 정산 신청은 매월 10일부터 20일까지 가능하며, 승인이 완료된 후 다음 달 말일에 등록된 PayPal 계정으로 지급됩니다. (단, 플랫폼이나 은행의 사정으로 인하여 예정된 입금일보다 지연될 수 있습니다. 양해 바랍니다.)",
    "withdrawal_directions2": "⁕ 정산 가능한 수익 잔액이 [2,000]위안을 초과하는 경우 수익정산 신청이 가능합니다.",
    "withdrawal_directions3": "⁕ 정산 서비스 수수료율은 회원등급에 따라 변경될 수 있습니다. (기본 수수료율은 30%입니다.)",
    "withdrawal_directions4": "⁕ 수익 결산 시 화폐 수익은 환율에 따라 변화한다",
    "withdrawal_paypal_not_fount": "Paypal 계정이 연결되지 않았어요. 지금 연결할까요?",
    "detail_date": "정산 신청일자",
    "detail_total": "신청금액",
    "detail_tax": "중국 세금",
    "detail_service_charge": "수수료",
    "detail_amount": "정산 금액",
    "detail_status": "정산 현황",
    "detail_action": "상세 보기",
    "detail_status_ongoing": "정산 중",
    "detail_status_success": "정산 성공",
    "detail_status_failure": "정산 실패",
    "detail_to_account_date": "예정일 {0}",
    "detail_view_details": "상세 보기",
    "account_paypal": "Paypal",
    "bank_identity_krw_title": "원화 계좌 정보",
    "bank_setting": "설정하기",
    "account_setting": "설정하기",
    "account_not_set": "미설정",
    "account_change": "계좌 정보 수정하기",
    "bank_change": "수정하기",
    "bank_not_set": "미설정",
    "bank_name": "은행",
    "bank_area": "은행 소재지",
    "bank_account_number": "계좌번호",
    "bank_account_name": "예금주",
    "bank_real": "소득신고자명",
    "bank_identity_number": "소득신고자 주민번호",
    "bank_email_code": "메일 인증번호",
    "account_email_code": "메일 인증번호",
    "bank_verify_header": "신분 인증",
    "bank_verify_code_title": "인증번호를 입력해주세요.",
    "bank_verify_content": "계정 안전을 위해 본인확인을 완료해주세요.\n이메일로 발송된 인증번호를 입력하세요.",
    "bank_verify_code_holder": "인증번호를 입력하세요",
    "bank_verify_code_error": "인증번호가 다릅니다.",
    "bank_verify_not_received": "인증번호를 받지 못하셨나요?",
    "bank_verify_modal_save": "인증하기",
    "bank_verify_success": "인증 성공",
    "bank_verify_failure": "인증 실패",
    "bank_change_modal_header": "정산 계좌/신분 정보 수정하기",
    "account_info_change_header": "계정정보 수정하기",
    "bank_name_holder": "은행을 선택해주세요.",
    "bank_area_holder": "은행 소재지를 선택하세요.",
    "bank_account_number_holder": "계좌번호를 입력해주세요.",
    "bank_account_name_holder": "예금주를 입력해주세요.",
    "bank_real_holder": "소득신고자명을 입력해주세요.",
    "bank_identity_number_holder": "소득신고자 주민번호를 입력해주세요.",
    "account_email_code_holder": "이메일 인증번호를 입력하세요.",
    "account_email_code_error": "인증번호 유효시간 초과 / 잘못된 인증번호입니다",
    "account_paypal_tips": "Paypal은 안전하고 편리한 결제 서비스를 제공합니다.\n페이팔 계정(이메일)을 입력하고 계정 정보 설정을 완료하세요. \n페이팔 공식 홈페이지({0})에서 가입/계정 정보를 확인하세요",
    "account_paypal_holder": "Paypal 계정 정보를 입력하세요",
    "account_paypal_error": "페이팔 계정(이메일)을 입력하세요",
    "bank_update_fail": "은행 정보 업데이트에 실패하였습니다!",
    "account_update_success": "계정 정보 수정이 완료되었습니다",
    "account_update_fail": "계정 정보 수정이 완료되지 않았습니다",
    "confirm_header": "저장",
    "detail_modal_period": "정산 기간",
    "detail_modal_detail": "상세 보기",
    "detail_modal_amount": "플랫폼 수익",
    "detail_modal_tax": "중국 세금",
    "detail_modal_service_fee": "수수료",
    "detail_modal_settlement_amount": "수익 정산 금액",
    "detail_modal_bank_info": "정산 계좌정보",
    "detail_modal_bank_btn": "입력하러 가기",
    "detail_modal_to_bank": "정산 계좌 정보를 입력하셔야 정산이 가능합니다.",
    "detail_modal_tips_time": "· 정산신청은 매월 10일~20일에 신청가능하며, 신청 후 30일 이내 등록하신 은행계좌로 입금됩니다.",
    "detail_modal_tips_level": "· 정산 서비스 수수료율은 회원등급에 따라 차등 적용됩니다. (기본 수수료율은 30%입니다.)",
    "detail_modal_tips_rate": "· 수익 결산 시 화폐 수익은 환율에 따라 변화한다",
    "detail_modal_tips_bank": "· 계좌정보가 부정확할 경우, 정산 신청이 취소될 수 있습니다.",
    "detail_modal_tips_tax": "· 사업소득 신고 의무에 따라 사업소득세 3.3%가 별도 공제됩니다.",
    "detail_modal_apply_btn": "위 내용을 모두 확인하였으며, 정산을 신청합니다.",
    "detail_drawer_money_rate": "적용 환율",
    "detail_drawer_money_amount": "정산 금액",
    "detail_drawer_tips_krw": "· 사업소득 신고 의무에 따라 사업소득세 3.3%가 별도 공제됩니다.",
    "modal_success_title": "정산 신청이 완료되었습니다",
    "modal_failure_title": "신청에 오류가 생겼습니다, 잠시 후 재신청해주세요.",
    "modal_close_remind": "{0}초 후에 자동으로 닫힙니다.",
    "platform_situation_title": "플랫폼 수익 개요",
    "platform_total": "월별 수익 (위안)",
    "platform_all_platform": "모든 플랫폼",
    "platform_detail": "플랫폼 수익 상세",
    "platform_table_name": "플랫폼",
    "platform_table_income": "플랫폼 수익 (위안)",
    "platform_table_fee": "수수료(위안)",
    "platform_table_tax": "중국 세금(위안)",
    "platform_table_balance": "출금 가능 금액(위안)",
    "platform_table_daily_income": "일별 수익",
    "platform_drawer_title": "{0} {1} 수익 상세",
    "platform_drawer_tips_wait": "플랫폼 별로 데이터 수집 시간이 다르므로 2-3일 정도의 수집 기간이 차이가 날 수 있습니다.",
    "platform_drawer_tips_unit": "수익 금액은 위안화 기준입니다.",
    "platform_drawer_tips_account": "일별 수익은 MCN 계정에 정산된 날을 기준으로 합니다.",
    "platform_drawer_date": "날짜",
    "platform_drawer_plan": "플랜",
    "platform_drawer_income": "플랫폼 수익 (위안)",
    "platform_drawer_fee": "수수료(위안)",
    "platform_drawer_tax": "중국 세금(위안)",
    "platform_drawer_balance": "출금 가능 금액(위안)",
    "platform_drawer_total": " 합계",
    "platform_drawer_collecting": "데이터 수집중...",
    "platform_drawer_show_more": "더보기",
    "platform_drawer_show_close": "접기",
    "platform_drawer_platform_all": "플랫폼 총 수익"
  },
  "setting": {
    "title": "설정",
    "subtitle": "adobaro 이용 관련 설정을 할 수 있습니다.",
    "user_info": "유저 정보",
    "account_security": "계정 보안",
    "notification": "알림",
    "protocol": "약관",
    "invitation": "초대",
    "user_partner": "소속 MCN",
    "user_partner_empty": "아직 소속된 MCN이 없어요",
    "user_partner_invite": "멤버 가입 요청을 받았어요",
    "user_partner_belong": "소속됨",
    "user_partner_name": "MCN:",
    "user_mcn_invite_header": "멤버십 초대 알림",
    "user_mcn_accepted_success": "멤버 초대를 수락했어요",
    "user_mcn_accepted_error": "오류가 발생했어요, 잠시 후에 다시 시도해주세요",
    "user_mcn_refused_success": "멤버 초대를 거절했어요",
    "user_mcn_refused_error": "오류가 발생했어요, 잠시 후에 다시 시도해주세요",
    "invitation_upgrade_content": "MCN 멤버 초대를 수락하면 PRO(MCN)요금제 혜택을 받을 수 있어요!\n현재 이용 플랜:{0}\n수락 시 기존 요금제는 자동 결제 취소되며, 결제금액은 이용 일자 수에 따라 환불 처리됩니다.\n멤버 초대를 수락하시겠어요?\n*결제금액 환불은 영업일 기준 최대 7일이 소요됩니다.",
    "user_partner_invite_title": "멤버 가입 초대를 받았어요!",
    "user_partner_invite_directions": "초대를 수락/거절하기 전에, 회사 정보를 꼭 확인하세요.\nMCN 소속 멤버는 PRO플랜 혜택을 받을 수 있어요:)",
    "user_partner_invite_tips": "*초대 수락시 유저정보가 MCN에 공개되며, MCN의 수익 정산 정산 정책에동의합니다.",
    "user_partner_invite_refuse": "거절하기",
    "user_partner_invite_footer_statement": "MCN 멤버로 소속된 순간부터 아도바로는 귀하와 파트너사간의 저작권 등 어떠한 분쟁에도 관여하지 않으며, 법적 책임이 없음을 알려드립니다.  과금 및 정산 관련 정책은 소속 MCN에 문의하세요.",
    "user_partner_auth": "MCN 권한 설정",
    "user_partner_auth_check": "모니터링 전용",
    "user_partner_auth_release": "업로드 가능",
    "user_partner_rescind": "계약해지 신청",
    "user_partner_auth_footer_statement": "MCN 권한 설정이 가능합니다.\n모니터링 전용 / 업로드 가능 권한을 선택하세요",
    "user_partner_rescind_title": "정말 계약 해지를 신청하시겠어요?",
    "user_partner_rescind_directions": "*파트너 계약 해지는 MCN과 회원간 사전 협의된 것으로 간주되며, 파트너 MCN에게 계약 종료 안내가 발송됩니다. \n\n파트너 MCN이 계약 해지에 동의하여야 계약이 종료되며, 파트너 권한은 파트너MCN의 계약 해지 동의 시점에 소멸됩니다.  \n\n계약 종료시 파트너 멤버십 혜택으로 제공하던 Pro 플랜은 종료되며, 기본 요금제로 변경됩니다. ",
    "user_partner_rescind_btn": "계약 해지",
    "user_uid": "UID",
    "user_phone": "휴대폰 번호",
    "user_email": "이메일",
    "user_password": "비밀번호",
    "user_third_party_login": "서드파티 로그인",
    "user_not_set": "미설정",
    "user_set_password": "비밀번호 수정",
    "user_to_change": "변경",
    "user_bound": "연동됨",
    "user_to_bound": "연동하기",
    "user_bound_google": "구글 계정에 연동됨",
    "user_unbound_google": "구글 계정에 연동하기",
    "message_google_bound": "다른 아도바로 계정에 연동된 구글 계정입니다.",
    "user_problem_center": "문의 센터",
    "user_phone_title_bind": "휴대폰 번호를 입력해 주세요.",
    "user_phone_subtitle_bind": "계정 보안을 위해 휴대폰 인증 후 진행이 가능합니다.",
    "user_phone_title_new": "신규 휴대폰 번호를 입력해 주세요.",
    "user_login_country_check": "국가를 선택해 주세요.",
    "user_modify_phone_verify_fail": "휴대폰 인증 실패",
    "user_support_email": "고객센터 이메일",
    "user_code_not_received_tip": "인증번호를 받지 못하셨다면 {0} 통해 처리해주시기 바랍니다.",
    "user_code_not_received": "인증번호를 못 받으셨나요?",
    "user_verify_header": "신분 인증",
    "user_modify_phone_header": "휴대폰 번호 변경",
    "user_modify_email_update_button": "이메일 변경",
    "user_modify_email_explanation": "계정 보안을 위해 이메일 인증 후 진행이 가능합니다.",
    "user_modify_email_old_holder": "현재 이메일을 입력해 주세요.",
    "user_modify_email_old_popover": "가입하신 이메일 주소가 기억나지 않으시면 {0} 통해 문의해주시기 바랍니다.",
    "user_modify_email_forgot": "이메일이 기억나지 않으시나요?",
    "user_modify_email_verify_fail": "이메일 인증 실패",
    "user_modify_new_email": "신규 이메일",
    "user_modify_email_new_holder": "신규 이메일을 입력해 주세요.",
    "user_modify_email_title": "이메일 변경",
    "user_modify_password_change_button": "비밀번호 변경",
    "user_modify_password_title": "비밀번호 변경",
    "user_modify_password_explanation": "계정 보안을 위해 이메일 인증번호 확인 후 진행이 가능합니다.",
    "user_modify_new_password": "신규 비밀번호",
    "user_modify_password_new_holder": "신규 비밀번호를 입력해 주세요.",
    "user_modify_password_confirm_holder": "신규 비밀번호를 재입력해 주세요.",
    "user_modify_password_head": "비밀번호 변경",
    "security_signin": "로그인 보안",
    "security_credit": "신용도",
    "security_signin_date": "로그인 시간",
    "security_signin_mode": "로그인 방식",
    "security_signin_third": "서드파티 로그인",
    "security_signin_google": "구글 로그인",
    "security_signin_account": "계정 로그인",
    "security_signin_ip": "IP주소",
    "security_signin_country": "국가",
    "security_signin_action": "집행",
    "delete_confirm_title": "정말 삭제하시겠습니까?",
    "credit_action_date": "집행시간",
    "credit_action_behavior": "행위",
    "credit_ score": "신용도",
    "notice_spread": "마케팅 알림",
    "notice_platform": "플랫폼 알림",
    "notice_sms": "문자 알림",
    "notice_email": "이메일 알림",
    "notice_adobaro": "adobaro",
    "notice_operate": "운영 플랫폼",
    "notice_select_holder": "선택해 주세요.",
    "notice_agree": "동의",
    "notice_disagree": "미동의",
    "user_problem_question_1": "1. 질문",
    "user_problem_answer_1": "답변",
    "user_problem_question_2": "2. 질문",
    "user_problem_answer_2": "답변",
    "user_problem_question_3": "3. 질문",
    "user_problem_answer_3": "답변",
    "user_problem_question_4": "4. 질문",
    "user_problem_answer_4": "답변",
    "protocol_user": "adobaro 사용자 사용계약",
    "protocol_privacy": "adobaro 개인정보 정책",
    "protocol_marketing": "마케팅 정보 수신 동의서",
    "notification_history": "알림 히스토리",
    "notice_date": "일시",
    "notice_title": "알림 제목",
    "notice_detail": "알림 내용",
    "delete_all_btn": "전부 삭제",
    "notice_delete_all": "전부 삭제하시겠습니까?",
    "notice_delete_all_subtitle": "전부 삭제 후, 알림 히스토리에서 확인 가능합니다.",
    "notice_cancel": "취소",
    "notice_details": "상세 내용",
    "notice_read_all": "모두 읽음",
    "notice_read_all_confirm": "모두 읽음으로 처리하시겠습니까?",
    "invitation_title": "친구초대",
    "invitation_link": "초대 링크",
    "invitation_copy_link": "복사",
    "invitation_copy_link_tip": "복사 성공",
    "invitation_copy_content": "저는 adobaro를 사용하고 있는데 추천 드립니다. 요즘 가장 핫한 크리에이터 콘텐츠 크로스보더 플랫폼, 함께 해보세요~",
    "invitation_bonus": "초대 리워드 내용",
    "invitation_points_rewards": "point",
    "invitation_signup": "adobaro 가입",
    "invitation_global_pass": "Global Pass 인증",
    "invitation_open_platform": "플랫폼 개통 (최초 한 번)",
    "invitation_publish_video": "콘텐츠 업로드(최초 1회)",
    "invitation_tips": "* 4가지 미션 모두 완료 시 100p 추가 지급",
    "invitation_history": "초대 히스토리",
    "invitation_account": "채널명",
    "invitation_time": "달성 시간",
    "invitation_type": "지급 사유",
    "invitation_type_extra": "추가지급",
    "invitation_type_unknown": "알 수 없음",
    "user_unbind": "해지 요청",
    "user_unbind_confirm_title": "계약 해지를 신청하시겠습니까?",
    "user_unbind_confirm_des": "해당 기업이 동의 후에 해지가 가능하며, 해지 후에는 기업 멤버 혜택 제공이 바로 종료됩니다.",
    "invitation_type_extra_invite": "초대_보너스",
    "invitation_signup_invite": "초대_adobaro 가입",
    "invitation_global_pass_invite": "초대_PASS 인증",
    "invitation_publish_video_invite": "초대_업로드 완료(최초 1회)",
    "invitation_open_platform_invite": "초대_플랫폼 개통 (최초 1회)"
  },
  "plan": {
    "segmented_month": "매월",
    "segmented_year": "매년",
    "segmented_year_discount": "16% 할인",
    "unit_month": "월",
    "unit_year": "년",
    "title_free": "FREE",
    "title_basic": "BASIC",
    "title_pro": "PRO",
    "subtitle_free": "활동을 위한 최소 Plan, 플랫폼 수익활동 가능",
    "subtitle_basic": "평균적인 활동을 하는 크리에이터 플랜, 1개월 무료 프로모션 진행, 정가 대비 90% 할인",
    "subtitle_pro": "모든 잠금 해제, 플랫폼별 무제한(최대) 활동 보장, 정가 대비 90% 할인",
    "original_label": "원가:",
    "btn_current": "현재 Plan",
    "btn_upgrade": "업그레이드",
    "btn_downgrade": "다운그레이드",
    "btn_convert": "전환",
    "btn_upgrade_experience": "업그레이드(1개월 무료 사용)",
    "btn_buy_now": "즉시 구매",
    "btn_buy_now_trial": "즉시 구매(1개월 무료체험)",
    "msg_exists": "서비스 업그레이드는 당분간 지원되지 않으니 기다려 주시기 바랍니다. ",
    "msg_paid": "결제가 완료되어 혜택이 업그레이드됩니다.",
    "price_tips_current": "현재 플랜입니다.",
    "price_tips_experience": "30일이 지난 후부터 결제가 시작됩니다.",
    "price_tips_new_plan": "선택 시 기존 플랜이 끝나는 시점에서 적용됩니다.",
    "price_tips_pay_upgrade": "차액 지불 후 업그레이드 가능",
    "price_tips_save_money": "매년 {0} 달러를 절약할 수 있습니다.",
    "expire_date": "{0}까지 유효합니다.",
    "rights_title": "주요 혜택",
    "rights_platform_label": "개통 가능 플랫폼",
    "rights_platform_free": "1개",
    "rights_platform_basic": "3개",
    "rights_platform_pro": "모두",
    "rights_service_charge_label": "정산 시 수수료 할인",
    "rights_service_charge_pro": "5%",
    "rights_upload_label": "업로드 횟수",
    "rights_upload_free": "2회/매주",
    "rights_upload_basic": "1회/플랫폼 별 매일",
    "rights_upload_pro": "무제한(플랫폼별 일 최대 5회)",
    "rights_space_label": "스토리지 제공",
    "rights_space_free": "5GB",
    "rights_space_basic": "5GB",
    "rights_space_pro": "10GB",
    "rights_check_label": "콘텐츠 사전 검수",
    "rights_check_basic": "√",
    "rights_check_pro": "√",
    "rights_copyright_label": "저작권 보호",
    "rights_copyright_pro": "√",
    "rights_settle_label": "빠른 정산",
    "rights_settle_pro": "√",
    "rights_professional_label": "전담 매니징 서비스",
    "rights_professional_pro": "√",
    "footer_current_plan": "현재 이용 중인 플랜:",
    "footer_update_plan": "갱신 예정",
    "footer_help_center": "도움말 센터에서 상세 보기",
    "details_title": "플랜표",
    "details_monthly_plan": "월간",
    "details_year_plan": "년간",
    "details_trial_one_month": "1개월 무료",
    "details_total": "합계",
    "details_deduction_monthly": "매월 결제됩니다.",
    "details_deduction_discount": "오늘은 결제되지 않습니다. ",
    "details_deduction_date": "{0}에 결제가 이루어집니다.",
    "payment_protocol": "구독 취소 시까지 매월 자동으로 요금이 부과되오니 {0} 및 {1}을(를) 읽어보시고 동의해 주시기 바랍니다. ",
    "payment_protocol_use": "사용자 사용계약",
    "payment_protocol_privacy": "개인 정보 정책",
    "result_success_title": "결제 성공",
    "result_success_subtitle": "해당 Plan의 혜택이 적용되었습니다. ",
    "result_success_btn": "사용해보기",
    "downgrade_limit_content": "현재 Plan은 {0}이기 때문에 플랫폼 이용이 제한됩니다. 업그레이드하시면 더 많은 플랫폼 개통과 다양한 혜택이 제공됩니다.",
    "downgrade_btn_keep": "{0} 유지",
    "downgrade_btn_renew": "지금 갱신",
    "downgrade_select_platform": "계속 운영할 플랫폼 {0}개를 선택해 주세요. 선택하지 않은 플랫폼은 잠김 처리됩니다. ",
    "downgrade_select_platform_unfroze": "현재 플랜은 {0}이며, {1}개의 플랫폼 사용이 가능합니다. 사용하실 플랫폼을 선택해 주세요.",
    "downgrade_msg_unfreeze_success": "잠금해제 성공",
    "downgrade_msg_unfreeze_fail": "잠금해제 실패"
  },
  "email": {
    "1": "제목과 소개 글 심사 관련",
    "2": "민감한 혹은 불량한 내용이 감지되었습니다.",
    "3": "콘텐츠 내용과 맞지 않습니다.",
    "4": "중국 법률에 위반되는 내용이 감지되었습니다.",
    "6": "썸네일 심사 관련",
    "7": "썸네일에 위반 내용이 포함되어 있습니다. 생식기나 민감한 부위가 노출되거나, 불쾌감을 주는 이미지이거나, 저속한 내용, 욕설, 공공질서와 반도덕적 내용이 포함되어 있을 수 있습니다.",
    "9": "썸네일이 심하게 늘어나거나 화면이 깨지거나 밝기가 너무 낮거나 지나치게 노출되었습니다.",
    "10": "썸네일의 해상도가 너무 낮습니다.",
    "11": "썸네일와 비디오 내용이 일치하지 않습니다.",
    "13": "배경이 제거됐거나, 인물이 일치하지 않거나, 저품질 이미지이기 때문에 거부되었습니다.",
    "14": "썸네일에 외부 콘텐츠나 정보가 포함되어 있습니다.",
    "15": "콘텐츠 심사 관련",
    "16": "콘텐츠의 품질 문제가 있습니다.",
    "17": "비디오의 해상도가 낮고 화면이 깨지며 밝기, 대비, 해상도, 화면이 흐릿하여 주체를 구분할 수 없습니다.",
    "18": "비디오에 잘못된 링크, 404 오류, 6초 미만의 길이가 있습니다.",
    "19": "비디오 콘텐츠에 명확한 스토리라인이 없습니다.",
    "20": "비디오 콘텐츠에 아무런 내용이 없습니다.",
    "21": "비디오의 재생이 원활하지 않고 버벅입니다.",
    "22": "화면이 확연하게 흔들리거나 5초 이상 계속 흔들리며 3초 이상 지속적으로 번쩍입니다. 정상적인 시청에 영향을 미칩니다.",
    "23": "화면의 주체가 완전하지 않고 지속적으로 (≥3초) 절단되었으며, 심하게 늘어나거나 압축되었으며, 양쪽에 검은 테두리가 있거나 검은 테두리로 채워져 있습니다.",
    "24": "콘텐츠에 오디오가 없어 거부되었습니다.",
    "25": "오프닝과 엔딩의 검은 화면이 3초를 초과하지 않아야 합니다.",
    "26": "이미지 품질이 낮습니다.",
    "27": "비디오 콘텐츠가 법적 문제를 일으킬 수 있습니다.",
    "28": "비디오 콘텐츠가 중국의 국가 지도자, 공격범, 검사, 국가 기관, 국기 등 이미지나 단어를 모욕하는 것으로 여겨져 중국 법을 위반할 우려가 있습니다.",
    "29": "비디오 콘텐츠에 불법이거나 위반적이며 저속한 성적, 피, 공포 관련 요소가 있습니다.",
    "30": "비디오 콘텐츠에 사이비 종교, 편가르기, 반동 조직 등과 관련된 요소가 있습니다.",
    "31": "비디오 콘텐츠가 미성년자나 장애인의 신체적 및 정신적 건강에 해를 끼칠 수 있는 내용이 있습니다.",
    "32": "미성년자는 대표자로서 상업적 마케팅 콘텐츠를 촬영할 수 없습니다.",
    "33": "비디오 콘텐츠가 공공질서, 사회적 가치관과 관련된 요소를 위반합니다. 외도, 가정 폭력, 과시, 차별, 선전, 흡연, 저속한 언어, 장난, 악동, 학대, 음주, 문신 등이 포함됩니다.",
    "34": "비디오 콘텐츠에 성적 암시 행동, 저속한 장면, 저속한 춤, 부적절한 남녀 관계 등이 있습니다.",
    "35": "비디오 콘텐츠에 심각한 노출이 포함되어 있습니다.",
    "36": "비디오의 음질에 문제가 있습니다.",
    "37": "갑자기 음소거되거나 소리가 사라지거나 오디오 트랙이 일관성 없이 겹쳐지거나 관련성이 없으며 음성과 영상이 심각하게 관련이 없습니다.",
    "38": "심한 잡음이 존재합니다.",
    "39": "자막의 품질 문제가 있습니다.",
    "40": "자막이 불명확하거나 깨져 있거나 중첩되거나 문장이 순서대로 전달되지 않습니다.",
    "41": "외국어 비디오에 중국어 자막이 없거나 자막의 품질 문제로 인해 추천되지 않습니다.",
    "42": "자막이 영상이나 오디오와 싱크되지 않거나 관련성이 없습니다.",
    "43": "잘린 자막이 존재합니다.",
    "44": "자막이 인물의 얼굴을 대부분 가리고 있습니다.",
    "45": "저작권 이슈",
    "46": "저작권자의 권익을 침해하는 내용의 게시를 금지합니다. 아래 내용이 포함되지만 이에 국한되지 않습니다:\n① 저작권자의 허가 없이 이미 공개되거나 아직 공개되지 않은 작품의 전파 및 판매\n② 저작권자 또는 기타 관련 이해관계자를 사칭\n③ 저작권자가 연재 금지한 내용 또는 사이트 내에서 오랜 기간 활동한 창작자의 내용을 카피",
    "47": "이미 업로드된 영상입니다. ",
    "signup_title": "환영합니다~",
    "signup_subtitle": "아도바로 가입 절차입니다.",
    "signup_set_password_tip": "계정 보안을 위해 이메일 인증 후 비밀번호를 설정할 수 있습니다.",
    "signup_set_password": "비밀번호 설정",
    "sigin_warning_title": "보안 이슈",
    "sigin_warning_subtitle": "해외 IP로 접속이 감지되었습니다.",
    "sigin_warning_midtitle": "계정 보안을 위해 아래 로그인 인증을 진행해 주세요.",
    "sigin_warning_verify": "로그인 인증",
    "sigin_warning_not_myself": "만약 본인이 로그인한게 아니라면",
    "sigin_warning_change_pwd": "비밀번호를 지금 즉시 변경해 주세요.",
    "sigin_warning_pwd": "비밀번호 변경",
    "reset_pwd_title": "비밀번호 재설정",
    "reset_pwd_subtitle": "비밀번호 재설정 요청을 받았습니다.",
    "reset_pwd_midtitle": "계정 보안을 위해 이메일 인증 후 비밀번호를 재설정할 수 있습니다.",
    "reset_pwd_set": "비밀번호 설정",
    "release_title": "업로드 현황",
    "release_audit_fail": "심사 반려",
    "release_upload_video": "업로드한 콘텐츠가",
    "release_audit_reason": "심사에 반려되었습니다. 반려 사유는 아래와 같습니다.",
    "release_re_upload": "다시 업로드",
    "release_success": "업로드 완료",
    "release_up_video": "업로드한 콘텐츠가",
    "release_up_platform": "{{.Platform}} 플랫폼에 업로드 되었습니다.",
    "release_view_data": "콘텐츠 데이터",
    "op_notice_title": "플랫폼 개통 현황",
    "op_notice_fail": "플랫폼 개통 실패",
    "op_notice_fail_platform": "신청하신 {{.Platform}} 플랫폼 개통이 반려되었습니다.",
    "op_notice_fail_reason": "반려 사유",
    "op_notice_success": "플랫폼 개통 성공",
    "op_notice_success_platform": "신청하신 {{.Platform}} 플랫폼이 개통 완료되었습니다.",
    "op_notice_ai": "adoba.AI를 통해 적합한 채널명과 채널 소개가 플랫폼에 최적화되어 적용되었습니다.",
    "op_notice_view_data": "플랫폼 정보 보기",
    "certification_title": "Pass 인증 통지",
    "certification_subtitle_global_fail": "Global Pass 심사 반려",
    "certification_global_fail": "신청하신 Global Pass 인증 심사가 반려되었습니다.",
    "certification_subtitle_creator_fail": "Creator Pass 심사 반려",
    "certification_creator_fail": "신청하신 Creator Pass 인증 심사가 반려되었습니다.",
    "certification_view_reason": "반려 사유",
    "certification_in_applying": "인증 심사가 통과되면, 다음 기능들을 활용하실 수 있습니다.",
    "certification_global_success": "Global Pass 인증 완료",
    "certification_subtitle_global_success": "신청하신 Global Pass 인증이 완료되었습니다.",
    "certification_open_now": "지금부터 다음 기능들을 활용하여 더 다양한 크리에이터 활동이 가능합니다.",
    "certification_subtitle_creator_success": "Creator Pass 인증 완료",
    "certification_creator_success": "신청하신 Creator Pass 인증이 완료되었습니다.",
    "certification_earnings": "더 많은 콘텐츠 수익 창출",
    "certification_auth_cancel": "Pass 인증 취소 통지",
    "certification_cancel_start": "{{开始时间}} 신청하신 Pass 인증 취소가 완료되었습니다.",
    "certification_get_a_coin": "신청일로부터 플랫폼 수익은 중지되지만, 아도바로 내 a.coin 은 사용 가능합니다.",
    "certification_a_coin": "a.coin 알아보기",
    "certification_re_register": "다시 플랫폼 개통 신청을 원한다면",
    "certification_cancel_end": "{{结束时间}} 이후에 플랫폼 재개통이 가능합니다.",
    "certification_thank": "이용해 주셔서 감사합니다.",
    "captcha_notice_title": "인증 번호 통지",
    "captcha_notice_subtitle": "인증번호 확인",
    "captcha_notice_hello": "안녕하세요,",
    "captcha_notice_platform": "플랫폼",
    "captcha_notice_send_code": "의 인증번호가 곧 발송됩니다.",
    "captcha_notice_platform_secure": "플랫폼 보안 문제로 휴대폰 인증번호가 꼭 필요합니다.",
    "captcha_notice_sgin": "수신 문자함을 확인해 주세요.\n만약 이미 adobaro 내에서 인증번호를 처리하였다면, 본 메일을 무시하셔도 됩니다.",
    "captcha_notice_fill_code": "인증번호 발송",
    "captcha_notice_not_myself": "만약, 본인이 신청한 것이 아니라면",
    "captcha_notice_handle": "아도바로 고객센터로 문의해 주시기 바랍니다.",
    "captcha_notice_foot": "본 이메일은 답변을 지원하지 않습니다.",
    "verify_code_title": "인증번호 알림",
    "verify_code_subtitle": "인증번호를 확인해 주세요.",
    "verify_code_hello": "안녕하세요,",
    "verify_code_platform": "플랫폼",
    "verify_code_send_code": "아래 버튼 선택 시 인증번호가 발송됩니다.",
    "verify_code_resend": "인증번호를 다시 확인해 주세요.",
    "verify_code_security": "플랫폼 보안 문제로 휴대폰 인증번호가 꼭 필요합니다.",
    "verify_code_sign": "유의해 주시기 바랍니다.",
    "verify_code_fill_code": "인증번호 입력",
    "email_subject_sign_account": "[adobaro] 가입을 환영합니다.",
    "email_subject_reset_pwd": "[adobaro] 비밀번호 변경 요청이 도착했습니다.",
    "email_subject_sign_warning": "[adobaro] 로그인 보안 이슈가 발견되었습니다.",
    "email_subject_auth": "[adobaro] Pass 인증 신청하셨나요?",
    "email_subject_release": "[adobaro] 업로드한 콘텐츠 진행사항입니다.",
    "email_subject_verify_code": "[adobaro] 보안을 위한 인증번호 요청입니다.",
    "email_subject_op_result": "[adobaro] 개통 신청하신 플랫폼 진행 결과입니다.",
    "email_snject_verify_email": "[adobaro] 인증을 위한 이메일입니다.",
    "email_subject_code_platform": "[adobaro] 플랫폼 개통을 위한 휴대폰 인증번호가 필요합니다.",
    "email_subject_code_plus": "[adobaro] Global Plus Pass을 위한 휴대폰 인증번호가 필요합니다.",
    "email_subject_code_auth": "[adobaro] Global Pass을 위한 휴대폰 인증번호가 필요합니다.",
    "email_subject_code_video": "[adobaro] 콘텐츠 업로드를 위한 휴대폰 인증번호가 필요합니다.",
    "email_verify_code_subtitle": "본인 인증을 위한 과정입니다.",
    "email_verify_code_hello": "안녕하세요. 본인 확인을 위해 인증번호가 발송됩니다.",
    "email_verify_code_input": "아래로 입장 후 인증을 진행해 주세요.",
    "email_verify_code_not_myself": "만약, 본인이 아니라면 로그인 후 이메일을 변경해 주세요.",
    "email_verify_code_handle": "인증번호는 고객센터에서도 요청하지 않으니, 타인에게 알려주거나, 유출되지 않도록 주의해 주시기 바랍니다.",
    "email_verify_code_recover": "본 이메일은 회신이 불가능합니다.",
    "email_verify_code_warning": "문제가 있으시면, 고객센터로 문의해 주세요.",
    "auth_err_1": "여권 사진 확인이 어렵습니다. 재확인하시고 업로드해주세요",
    "auth_err_2": "본인과 여권 사진의 대조 확인이 어렵습니다. 다시 확인 후 업로드해 주세요.",
    "auth_err_3": "여권 유효기간이 지났습니다. 업데이트해 주세요.",
    "auth_err_4": "여권 정보가 일치하지 않습니다. 정보를 정확히 입력해주세요",
    "auth_err_5": "동일한 여권 정보가 존재합니다.",
    "auth_err_6": "스크린샷이 선명하지 않습니다. 다시 확인 후 업로드해 주세요.",
    "auth_err_7": "본인과 스크린샷의 대조 확인이 어렵습니다. 다시 확인 후 업로드해 주세요.",
    "auth_err_8": "이미지에서 추출된 정보와 기입하신 정보가 일치하지 않습니다. 다시 확인 후 업로드해 주세요.",
    "auth_err_9": "동일한 대쉬보드 정보가 존재합니다.",
    "5 (这个不会存在）": "글자 수를 초과하였습니다.",
    "8 （不存在）": "썸네일에 테두리가 있습니다.",
    "12 (不存在)": "썸네일의 텍스트가 잘립니다.",
    "release_1": "업로드가 실패되었습니다.",
    "release_2": "안녕하세요",
    "release_3": "{{.Platform}}에 업로드한 영상이",
    "release_4": "아래의 이유로 업로드가 실패되었습니다.",
    "release_5": "재업로드 하러 가기"
  }
};
export default ko